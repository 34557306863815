export const SIGN_IN = 'authenticate/SIGN_IN';
export const SIGN_IN_SUCCESS = 'authenticate/SIGN_IN_SUCCESS';
export const SIGN_IN_FAIL = 'authenticate/SIGN_IN_FAIL';

export const SIGN_UP = 'authenticate/SIGN_UP';
export const SIGN_UP_SUCCESS = 'authenticate/SIGN_UP_SUCCESS';
export const SIGN_UP_FAIL = 'authenticate/SIGN_UP_FAIL';

export const LOG_OUT = 'authenticate/LOG_OUT';
export const LOG_OUT_SUCCESS = 'authenticate/LOG_OUT_SUCCESS';
export const LOG_OUT_FAIL = 'authenticate/LOG_OUT_FAIL';

export const RESTORE_PASSWORD = 'authenticate/RESTORE_PASSWORD';
export const RESTORE_PASSWORD_SUCCESS = 'authenticate/RESTORE_PASSWORD_SUCCESS';
export const RESTORE_PASSWORD_FAIL = 'authenticate/RESTORE_PASSWORD_FAIL';

export const SESSION_REFRESH = 'authenticate/SESSION_REFRESH';
export const SESSION_REFRESH_SUCCESS = 'authenticate/SESSION_REFRESH_SUCCESS';
export const SESSION_REFRESH_FAIL = 'authenticate/SESSION_REFRESH_FAIL';
