export const SET_INITIAL_VALUES = 'bid/SET_INITIAL_VALUES';
export const GET_ALL_BIDS = 'bid/GET_ALL_BIDS';
export const GET_ALL_BIDS_SUCCESS = 'bid/GET_ALL_BIDS_SUCCESS';
export const GET_ALL_BIDS_FAIL = 'bid/GET_ALL_BIDS_FAIL';
export const GET_BID_BY_ID = 'bid/GET_BID_BY_ID';
export const GET_BID_BY_ID_SUCCESS = 'bid/GET_BID_BY_ID_SUCCESS';
export const GET_BID_BY_ID_FAIL = 'bid/GET_BID_BY_ID_FAIL';
export const CREATE_BID = 'bid/CREATE_BID';
export const CREATE_BID_SUCCESS = 'bid/CREATE_BID_SUCCESS';
export const CREATE_BID_FAIL = 'bid/CREATE_BID_FAIL';
export const UPDATE_BID = 'bid/UPDATE_BID';
export const UPDATE_BID_SUCCESS = 'bid/UPDATE_BID_SUCCESS';
export const UPDATE_BID_FAIL = 'bid/UPDATE_BID_FAIL';
export const REMOVE_BID = 'bid/REMOVE_BID';
export const REMOVE_BID_SUCCESS = 'bid/REMOVE_BID_SUCCESS';
export const REMOVE_BID_FAIL = 'bid/REMOVE_BID_FAIL';
export const TRIGGER_BID_MODAL = 'bid/TRIGGER_BID_MODAL';
