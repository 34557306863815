import * as types from './types';

import { JOBS, JobActionTypes, job } from './model';
import { initialStore } from '../initial-store';

export const jobReducer = (state = initialStore.jobs, action: JobActionTypes): JOBS => {
  switch (action.type) {
    case types.GET_ALL_JOBS: {
      return { ...state, isFetching: true };
    }
    case types.GET_ALL_JOBS_SUCCESS: {
      const { count, rows } = action.payload;
      const jobsSelectOptions = rows.map((el: job) => {
        return { value: el.id, title: `${el.title}` };
      });
      return { ...state, listOfJobs: rows, count, isFetching: false, jobsSelectOptions };
    }
    case types.GET_ALL_JOBS_FAIL: {
      return { ...state, error: action.payload, isFetching: false };
    }
    case types.CREATE_JOB: {
      return { ...state };
    }
    case types.CREATE_JOB_SUCCESS: {
      const { job, limit, page } = action.payload;
      if (state.count < limit) {
        return {
          ...state,
          count: state.count + 1,
          listOfJobs: [job, ...state.listOfJobs],
        };
      }

      if (page === 1) {
        return {
          ...state,
          count: state.count + 1,
          listOfJobs: [job, ...state.listOfJobs.slice(0, -1)],
        };
      }
      return { ...state, count: state.count + 1 };
    }
    case types.CREATE_JOB_FAIL: {
      return { ...state, error: action.payload };
    }
    case types.UPDATE_JOB: {
      return { ...state };
    }
    case types.UPDATE_JOB_SUCCESS: {
      return {
        ...state,
        listOfJobs: state.listOfJobs.map((el) => (el.id === action.payload.id ? action.payload : el)),
      };
    }
    case types.UPDATE_JOB_FAIL: {
      return { ...state, error: action.payload };
    }
    case types.REMOVE_JOB: {
      return { ...state, isFetching: true };
    }
    case types.REMOVE_JOB_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        listOfJobs: state.listOfJobs.filter((el) => el.id !== action.payload.id),
      };
    }
    case types.REMOVE_JOB_FAIL: {
      return { ...state, isFetching: false, error: action.payload };
    }
    case types.GET_CURRENT_JOB: {
      return { ...state };
    }
    case types.GET_CURRENT_JOB_SUCCESS: {
      return { ...state, currentJob: action.payload, isAuthorized: true };
    }
    case types.GET_CURRENT_JOB_FAIL: {
      return { ...state, error: action.payload };
    }
    case types.SET_DEFAULT_PASSWORD: {
      return { ...state, isFetching: true };
    }
    case types.SET_DEFAULT_PASSWORD_SUCCESS: {
      return { ...state, isFetching: false };
    }
    case types.SET_DEFAULT_PASSWORD_FAIL: {
      return { ...state, error: action.payload, isFetching: false };
    }

    case types.SET_INITIAL_VALUES: {
      if (!action.payload) return { ...state, initialValues: initialStore.jobs.initialValues };

      const element = state.listOfJobs.find((el) => el.id === action.payload);
      if (!element) return state;
      const { id, title, desc, price, isPrivate, expiration } = element;
      return { ...state, initialValues: { id, title, desc, price, isPrivate, expiration } };
    }

    default:
      return state;
  }
};
