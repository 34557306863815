import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import {
  UserOutlined,
  HomeOutlined,
  DollarOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  FileDoneOutlined,
  AuditOutlined,
} from '@ant-design/icons';
import { RouteComponentProps } from 'react-router-dom';

// CONSTANTS
import { URLS } from '../../constants';
import { ADMIN_ROLE, AVAILABLE_ROLES } from '../../constants';

// COMPONENTS
import { CustomHeader, Logo } from '..';

// UTILS
import { checkJWT } from '../../utils/check-jwt';

import svgLogo from '../../assets/react.svg';
import './style.scss';

type LAYOUT_PROPS = {
  children: React.ReactNode;
};

const { Sider, Content, Header } = Layout;
export const CustomLayout = (props: LAYOUT_PROPS & RouteComponentProps) => {
  const { children, location, history } = props;
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [, role] = checkJWT();

  const toggle = () => setCollapsed(!collapsed);

  const changeLocation = ({ path, external }: { path: string; external: boolean }) => {
    if (external) return window.open(path, '_blank');

    history.push(path);
  };

  const MAIN_ROUTES = [
    {
      title: 'Main',
      path: URLS.main,
      hasAccess: AVAILABLE_ROLES,
      icon: <HomeOutlined />,
      external: false,
    },
    {
      title: 'Admins',
      path: URLS.admins,
      hasAccess: [ADMIN_ROLE],
      icon: <UserOutlined />,
      external: false,
    },
    {
      title: 'Members',
      path: URLS.members,
      hasAccess: [ADMIN_ROLE],
      icon: <UserOutlined />,
      external: false,
    },
    {
      title: 'Jobs',
      path: URLS.jobs,
      hasAccess: [ADMIN_ROLE],
      icon: <DollarOutlined />,
      external: false,
    },
    {
      title: 'Contracts',
      path: URLS.contracts,
      hasAccess: [ADMIN_ROLE],
      icon: <FileDoneOutlined />,
      external: false,
    },
    {
      title: 'Bids',
      path: URLS.bids,
      hasAccess: [ADMIN_ROLE],
      icon: <AuditOutlined />,
      external: false,
    },
    {
      title: 'Feedbacks',
      path: URLS.feedbacks,
      hasAccess: [ADMIN_ROLE],
      icon: <AuditOutlined />,
      external: false,
    },
  ];

  return (
    <Layout className="layout">
      <Sider trigger={null} collapsible collapsed={collapsed} width={250} className="layout__slider">
        <div className="layout__logo-box">
          <Logo className="layout__logo" logo={svgLogo} />
          <h1 id="company-title">Your Company</h1>
        </div>
        <Menu mode="inline" className="layout__menu" selectedKeys={[location.pathname]}>
          {MAIN_ROUTES.filter((el) => el.hasAccess.length === 0 || el.hasAccess.some((el) => el === role)).map(
            ({ title, path, icon, external }) => (
              <Menu.Item
                key={path}
                className="layout__menu-item"
                onClick={changeLocation.bind(null, { path, external })}
              >
                {icon && !collapsed && <div className="layout__icon-box">{icon}</div>}
                {icon && collapsed && icon}
                <span id="item-title">{title}</span>
              </Menu.Item>
            )
          )}
        </Menu>
      </Sider>
      <Layout>
        <Header className="layout__header">
          <div>{collapsed ? <MenuFoldOutlined onClick={toggle} /> : <MenuUnfoldOutlined onClick={toggle} />}</div>
          <CustomHeader {...props} />
        </Header>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};
