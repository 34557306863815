import * as types from './types';

import { ADMINS, AdminActionTypes } from './model';
import { initialStore } from '../initial-store';

export const adminsReducer = (state = initialStore.admins, action: AdminActionTypes): ADMINS => {
  switch (action.type) {
    case types.GET_ALL_ADMINS: {
      return { ...state, isFetching: true };
    }
    case types.GET_ALL_ADMINS_SUCCESS: {
      return { ...state, listOfAdmins: action.payload.rows, count: action.payload.count, isFetching: false };
    }
    case types.GET_ALL_ADMINS_FAIL: {
      return { ...state, error: action.payload, isFetching: false };
    }
    case types.CREATE_ADMIN: {
      return { ...state };
    }
    case types.CREATE_ADMIN_SUCCESS: {
      const { admin, limit, page } = action.payload;
      if (state.count < limit) {
        return {
          ...state,
          count: state.count + 1,
          listOfAdmins: [admin, ...state.listOfAdmins],
        };
      }

      if (page === 1) {
        return {
          ...state,
          count: state.count + 1,
          listOfAdmins: [admin, ...state.listOfAdmins.slice(0, -1)],
        };
      }
      return { ...state, count: state.count + 1 };
    }
    case types.CREATE_ADMIN_FAIL: {
      return { ...state, error: action.payload };
    }
    case types.UPDATE_ADMIN: {
      return { ...state };
    }
    case types.UPDATE_ADMIN_SUCCESS: {
      return {
        ...state,
        listOfAdmins: state.listOfAdmins.map((el) => (el.id === action.payload.id ? action.payload : el)),
      };
    }
    case types.UPDATE_ADMIN_FAIL: {
      return { ...state, error: action.payload };
    }
    case types.REMOVE_ADMIN: {
      return { ...state, isFetching: true };
    }
    case types.REMOVE_ADMIN_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        listOfAdmins: state.listOfAdmins.filter((el) => el.id !== action.payload.id),
      };
    }
    case types.REMOVE_ADMIN_FAIL: {
      return { ...state, isFetching: false, error: action.payload };
    }
    case types.GET_CURRENT_ADMIN: {
      return { ...state };
    }
    case types.GET_CURRENT_ADMIN_SUCCESS: {
      return { ...state, currentAdmin: action.payload };
    }
    case types.GET_CURRENT_ADMIN_FAIL: {
      return { ...state, error: action.payload };
    }
    case types.SET_DEFAULT_PASSWORD: {
      return { ...state, isFetching: true };
    }
    case types.SET_DEFAULT_PASSWORD_SUCCESS: {
      return { ...state, isFetching: false };
    }
    case types.SET_DEFAULT_PASSWORD_FAIL: {
      return { ...state, error: action.payload, isFetching: false };
    }

    case types.SET_INITIAL_VALUES: {
      if (!action.payload) return { ...state, initialValues: initialStore.admins.initialValues };

      const element = state.listOfAdmins.find((el) => el.id === action.payload);
      if (!element) return state;
      const { id, name, email, picture } = element;
      return { ...state, initialValues: { id, name, email, picture } };
    }

    default:
      return state;
  }
};
