import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { URLS } from '../../constants';
import { checkJWT } from '../../utils/check-jwt';

import { CustomLayout } from '../layout';
import { CommonInfoLayout } from '../layout/commonInfoLauout';

type PRIVATEROUTE = {
  component: any;
  exact?: boolean;
  path: string | undefined;
  layoutComponent: boolean;
  hidden?: boolean;
  hasAccess: string[];
};

export const PrivateRoute = ({
  component: Component,
  exact = true,
  path,
  layoutComponent,
  hidden,
  hasAccess,
}: PRIVATEROUTE) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        const [userId, role] = checkJWT();

        if (hidden && !userId) return <Redirect to={URLS.auth} />;

        if (hasAccess.length !== 0 && !hasAccess.some((el) => el === role)) return <Redirect to={URLS.auth} />;

        if (!layoutComponent)
          return (
            <Suspense fallback={null}>
              <Component {...props} />
            </Suspense>
          );

        return (
          <Suspense fallback={null}>
            <CommonInfoLayout>
              <CustomLayout {...props}>
                <Component {...props} />
              </CustomLayout>
            </CommonInfoLayout>
          </Suspense>
        );
      }}
    />
  );
};
