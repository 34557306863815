import * as types from './types';
import * as model from './model';
import { inferLiteral } from './model';

export const getAllAdmins = (payload: model.getAllAdminsPayload) =>
  ({
    type: inferLiteral(types.GET_ALL_ADMINS),
    payload,
  } as const);

export const getAllAdminsSuccess = (payload: model.getAllAdminsSuccessPayload) =>
  ({
    type: inferLiteral(types.GET_ALL_ADMINS_SUCCESS),
    payload,
  } as const);

export const getAllAdminsFail = (payload: model.getAllAdminsFailPayload) =>
  ({
    type: inferLiteral(types.GET_ALL_ADMINS_FAIL),
    payload,
  } as const);

export const createAdmin = (payload: model.createAdminPayload) =>
  ({
    type: inferLiteral(types.CREATE_ADMIN),
    payload,
  } as const);

export const createAdminSuccess = (payload: model.createAdminSuccessPayload) =>
  ({
    type: inferLiteral(types.CREATE_ADMIN_SUCCESS),
    payload,
  } as const);

export const createAdminFail = (payload: model.createAdminFailPayload) =>
  ({
    type: inferLiteral(types.CREATE_ADMIN_FAIL),
    payload,
  } as const);

export const updateAdmin = (payload: model.updateAdminPayload) =>
  ({
    type: inferLiteral(types.UPDATE_ADMIN),
    payload,
  } as const);

export const updateAdminSuccess = (payload: model.updateAdminSuccessPayload) =>
  ({
    type: inferLiteral(types.UPDATE_ADMIN_SUCCESS),
    payload,
  } as const);

export const updateAdminFail = (payload: model.updateAdminFailPayload) =>
  ({
    type: inferLiteral(types.UPDATE_ADMIN_FAIL),
    payload,
  } as const);

export const removeAdmin = (payload: model.removeAdminPayload) =>
  ({
    type: inferLiteral(types.REMOVE_ADMIN),
    payload,
  } as const);

export const removeAdminSuccess = (payload: model.removeAdminSuccessPayload) =>
  ({
    type: inferLiteral(types.REMOVE_ADMIN_SUCCESS),
    payload,
  } as const);

export const removeAdminFail = (payload: model.removeAdminFailPayload) =>
  ({
    type: inferLiteral(types.REMOVE_ADMIN_FAIL),
    payload,
  } as const);

export const getCurrentAdmin = () =>
  ({
    type: inferLiteral(types.GET_CURRENT_ADMIN),
  } as const);

export const getCurrentAdminSuccess = (payload: model.getCurrentAdminSuccessPayload) =>
  ({
    type: inferLiteral(types.GET_CURRENT_ADMIN_SUCCESS),
    payload,
  } as const);

export const getCurrentAdminFail = (payload: model.getCurrentAdminFailPayload) =>
  ({
    type: inferLiteral(types.GET_CURRENT_ADMIN_FAIL),
    payload,
  } as const);

export const setDefaultPassword = (payload: model.setDefaultPasswordPayload) =>
  ({
    type: inferLiteral(types.SET_DEFAULT_PASSWORD),
    payload,
  } as const);

export const setDefaultPasswordSuccess = () =>
  ({
    type: inferLiteral(types.SET_DEFAULT_PASSWORD_SUCCESS),
  } as const);

export const setDefaultPasswordFail = (payload: model.setDefaultPasswordFailPayload) =>
  ({
    type: inferLiteral(types.SET_DEFAULT_PASSWORD_FAIL),
    payload,
  } as const);

export const setInitialValues = (payload?: model.setInitialValuesPayload) =>
  ({
    type: inferLiteral(types.SET_INITIAL_VALUES),
    payload,
  } as const);
