import { STORE } from './model';
import { job } from './job/model';
import { contract } from './contract/model';

export const initialStore: STORE = {
  auth: {
    isFetching: false,
    error: '',
    isAuthorized: false,
  },
  admins: {
    isFetching: false,
    error: '',
    currentAdmin: {
      id: '',
      name: '',
      email: '',
      picture: '',
      createdAt: '',
    },
    listOfAdmins: [],
    count: 0,
    initialValues: {
      id: '',
      name: '',
      email: '',
      picture: '',
    },
  },
  jobs: {
    isFetching: false,
    isAuthorized: false,
    error: '',
    currentJob: {
      id: '',
      title: '',
      desc: '',
      price: 0,
      createdAt: '',
      expiration: '',
      isPrivate: false,
    },
    listOfJobs: [] as job[],
    count: 0,
    initialValues: {
      id: '',
      title: '',
      desc: '',
      price: 0,
      expiration: '',
      isPrivate: false,
    },
    jobsSelectOptions: [],
  },
  bids: {
    isFetching: false,
    error: '',
    currentBid: null,
    bidList: {
      rows: [],
      count: 0,
    },
    initialValues: {
      id: '',
      status: '',
      desc: '',
      price: 0,
    },
    modalOptions: {
      show: false,
      isUpdate: false,
    },
    bidsSelectOptions: [],
  },
  contracts: {
    isFetching: false,
    isAuthorized: false,
    error: '',
    filter: {
      implementer: undefined,
      purchaser: undefined,
    },
    currentContract: {
      id: '',
      status: '',
      deadline: '',
      bidPrice: '',
      purchaser: '',
    },
    contractList: [] as contract[],
    count: 0,
    initialValues: {
      bid: '',
      status: '',
      deadline: '',
    },
    updateContractId: '',
    modalOptions: {
      show: false,
      isUpdate: false,
    },
  },
  members: {
    isFetching: false,
    error: '',
    listOfMembers: [],
    count: 0,
    membersSelectOptions: [],
    implementerSelectOptions: [],
    purchaserSelectOptions: [],
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      avatar: '',
      type: '',
      confirmed: false,
    },
    updateMemberId: '',
    modalOptions: {
      show: false,
      isUpdate: false,
    },
  },
  commonInfos: {
    isFetching: false,
    error: '',
    bidsStatusesSelectOptions: [],
    jobsStatusesSelectOptions: [],
    contractsStatusesSelectOptions: [],
  },
};
