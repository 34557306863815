import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

// ACTIONS
import { getCurrentAdmin } from '../../store/admins/actions';
import { logOut, sessionRefresh } from '../../store/authenticate/actions';

// MODELS
import { STORE } from '../../store/model';
import { getCurrentAdminType } from '../../store/admins/model';
import { LogOutType, SessionRefreshType } from '../../store/authenticate/model';

// import { CURRENT_USER } from '../../models/users';

// CONSTANTS
import { ROUTES } from '../index';
import { URLS } from '../../constants';

// COMPONENTS
import { PrivateRoute } from '../../components/private-route';

// UTILS
import { getToken, getRefreshToken, removeToken, removeRefreshToken } from '../../utils/localstage-actions';

const CHECK_EXPIRED_TIME = 120000; //2min
const USER_IDLE_TIME = 1200000; //20min

interface OwnProps {}

interface StoreProps {}

interface AppState {
  currentToken: string;
}

interface DispatchProps {
  getCurrentAdmin: getCurrentAdminType;
  logOut: LogOutType;
  sessionRefresh: SessionRefreshType;
}

type AppInterface = StoreProps & DispatchProps & OwnProps;

class App extends Component<AppInterface, AppState> {
  events: string[];
  setIntervalId: number;

  constructor(props: AppInterface) {
    super(props);

    this.state = {
      currentToken: '',
    };
    this.events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
    this.setIntervalId = 0;
  }

  componentDidMount() {
    console.log('TCL: App -> componentDidMount -> componentDidMount');
    const { getCurrentAdmin, sessionRefresh, logOut } = this.props;
    const token = getToken();

    if (token) {
      getCurrentAdmin();
    }

    this.events.forEach((event) => window.addEventListener(event, this.setLastTimeAction));
    this.setLastTimeAction();

    this.setIntervalId = window.setInterval(() => {
      const accessToken = getToken() && getToken() !== null ? getToken() : '';
      const decoded: any = accessToken ? jwtDecode(accessToken) : '';

      if (accessToken && decoded.exp * 1000 < Date.now()) {
        const lastTimeUserActive = localStorage.getItem('lastTimeUserActive');
        const numberLastTimeUserActive = lastTimeUserActive !== null ? parseInt(lastTimeUserActive, 10) : 0;
        if (Date.now() - numberLastTimeUserActive < USER_IDLE_TIME) {
          sessionRefresh({
            expired_access_token: accessToken,
            refresh_token: getRefreshToken(),
            access_token: process.env.REACT_APP_MASTER_KEY,
          });
        } else {
          logOut({ refreshToken: getRefreshToken(), accessToken });
          removeToken();
          removeRefreshToken();
          localStorage.removeItem('lastTimeUserActive');
          window.location.replace(URLS.auth);
        }
      }
    }, CHECK_EXPIRED_TIME);
  }

  componentWillUnmount() {
    this.destroy();
  }

  setLastTimeAction = () => {
    localStorage.setItem('lastTimeUserActive', Date.now().toString());
  };

  destroy = () => {
    this.events.forEach((event) => window.removeEventListener(event, this.setLastTimeAction));
    localStorage.removeItem('lastTimeUserActive');
    clearInterval(this.setIntervalId);
  };

  render() {
    return (
      <Switch>
        {ROUTES.map((el) => (
          <PrivateRoute
            key={el.id}
            exact={el.exact}
            path={el.path}
            component={el.component}
            layoutComponent={el.layoutComponent}
            hidden={el.hidden}
            hasAccess={el.hasAccess}
          />
        ))}
      </Switch>
    );
  }
}

export default connect<StoreProps, DispatchProps, OwnProps, STORE>(null, { getCurrentAdmin, logOut, sessionRefresh })(
  App
);
