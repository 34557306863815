import { http } from '../utils/axios';

import { API_URLS } from '../constants';
import {
  getAllContractsPayload,
  removeContractPayload,
  createContractPayload,
  updateContractPayload,
} from '../store/contract/model';

export const contractApi = {
  getAllContractsRequest(params: getAllContractsPayload) {
    return http({ url: API_URLS.contracts, params }).then((res) => res.data);
  },
  createContractRequest(payload: createContractPayload['values']) {
    return http({ url: API_URLS.contracts, method: 'POST', data: { ...payload } }).then((res) => res.data);
  },
  updateContractRequest({ id, values }: { values: updateContractPayload; id: string }) {
    return http({ url: `${API_URLS.contracts}/${id}`, method: 'PUT', data: { ...values } }).then((res) => res.data);
  },
  removeContractRequest(payload: removeContractPayload) {
    return http({ url: `${API_URLS.contracts}/${payload.id}`, method: 'DELETE' }).then((res) => res.data);
  },
};
