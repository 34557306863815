import { fork } from 'redux-saga/effects';

import auth from './authenticate';
import admins from './admins';
import jobs from './job';
import contracts from './contract';
import member from './member';
import bid from './bid';
import commonInfo from './commonInfo';

export default function* () {
  yield fork(auth);
  yield fork(admins);
  yield fork(jobs);
  yield fork(contracts);
  yield fork(member);
  yield fork(bid);
  yield fork(commonInfo);
}
