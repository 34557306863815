import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// APOLLO GRAPHQL
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';

// UTILS
import { getToken } from './utils/localstage-actions';

import App from './route/App';
import * as serviceWorker from './serviceWorker';

// styles
import './styles/index.scss';

// redux
import store from './store';

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_BASE_API_URL}/graphql`,
  request: (operation) => {
    const token = getToken();
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    });
  },
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept('./route/App', () => {
    const NextApp = require('./route/App').default;
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <NextApp />
        </BrowserRouter>
      </Provider>,
      document.getElementById('root')
    );
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
