import * as types from './types';
import { CommonInfoActionTypes, COMMONINFOS } from './model';

import { initialStore } from '../initial-store';

export const commoninfoReducer = (state = initialStore.commonInfos, action: CommonInfoActionTypes): COMMONINFOS => {
  switch (action.type) {
    case types.GET_ALL_COMMONINFOS: {
      return { ...state, isFetching: true };
    }
    case types.GET_ALL_COMMONINFOS_SUCCESS: {
      const { bidsStatuses, jobsStatuses, contractsStatuses } = action.payload;
      const bidsStatusesSelectOptions = bidsStatuses.default.map((status) => {
        return { value: status, title: status };
      });
      const jobsStatusesSelectOptions = jobsStatuses.default.map((status) => {
        return { value: status, title: status };
      });
      const contractsStatusesSelectOptions = contractsStatuses.default.map((status) => {
        return { value: status, title: status };
      });
      return {
        ...state,
        ...action.payload,
        bidsStatusesSelectOptions,
        jobsStatusesSelectOptions,
        contractsStatusesSelectOptions,
      };
    }
    case types.GET_ALL_COMMONINFOS_FAIL: {
      return { ...state, isFetching: false, error: action.payload };
    }

    default:
      return state;
  }
};
