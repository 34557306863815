import * as types from './types';
import * as model from './model';
import { inferLiteral } from './model';

// getAllMembers
export const getAllMembers = (payload: model.getAllMembersPayload) =>
  ({
    type: inferLiteral(types.GET_ALL_MEMBERS),
    payload,
  } as const);
export const getAllMembersSuccess = (payload: model.getAllMemberSuccessPayload) =>
  ({
    type: inferLiteral(types.GET_ALL_MEMBERS_SUCCESS),
    payload,
  } as const);
export const getAllMembersFail = (payload: model.getAllMemberFailPayload) =>
  ({
    type: inferLiteral(types.GET_ALL_MEMBERS_FAIL),
    payload,
  } as const);

// createMember
export const createMember = (payload: model.createMemberPayload) =>
  ({
    type: inferLiteral(types.CREATE_MEMBER),
    payload,
  } as const);
export const createMemberSuccess = (payload: model.createMemberSuccessPayload) =>
  ({
    type: inferLiteral(types.CREATE_MEMBER_SUCCESS),
    payload,
  } as const);
export const createMemberFail = (payload: model.createMemberFailPayload) =>
  ({
    type: inferLiteral(types.CREATE_MEMBER_FAIL),
    payload,
  } as const);

// updateMember
export const updateMember = (payload: model.updateMemberPayload) =>
  ({
    type: inferLiteral(types.UPDATE_MEMBER),
    payload,
  } as const);
export const updateMemberSuccess = (payload: model.updateMemberSuccessPayload) =>
  ({
    type: inferLiteral(types.UPDATE_MEMBER_SUCCESS),
    payload,
  } as const);
export const updateMemberFail = (payload: model.updateMemberFailPayload) =>
  ({
    type: inferLiteral(types.UPDATE_MEMBER_FAIL),
    payload,
  } as const);

// removeMember
export const removeMember = (payload: model.removeMemberPayload) =>
  ({
    type: inferLiteral(types.REMOVE_MEMBER),
    payload,
  } as const);
export const removeMemberSuccess = (payload: model.removeMemberSuccessPayload) =>
  ({
    type: inferLiteral(types.REMOVE_MEMBER_SUCCESS),
    payload,
  } as const);
export const removeMemberFail = (payload: model.removeMemberFailPayload) =>
  ({
    type: inferLiteral(types.REMOVE_MEMBER_FAIL),
    payload,
  } as const);

// setDefaultPassword
export const setDefaultPassword = (payload: model.setDefaultPasswordPayload) =>
  ({
    type: inferLiteral(types.SET_DEFAULT_PASSWORD),
    payload,
  } as const);
export const setDefaultPasswordSuccess = () =>
  ({
    type: inferLiteral(types.SET_DEFAULT_PASSWORD_SUCCESS),
  } as const);
export const setDefaultPasswordFail = (payload: model.setDefaultPasswordFailPayload) =>
  ({
    type: inferLiteral(types.SET_DEFAULT_PASSWORD_FAIL),
    payload,
  } as const);

// resendConfirmationEmail
export const sendConfirmationEmail = (payload: model.sendConfirmationEmailPayload) =>
  ({
    type: inferLiteral(types.SEND_CONFIRMATION_EMAIL),
    payload,
  } as const);
export const sendConfirmationEmailSuccess = () =>
  ({
    type: inferLiteral(types.SEND_CONFIRMATION_EMAIL_SUCCESS),
  } as const);
export const sendConfirmationEmailFail = (payload: model.sendConfirmationEmailFailPayload) =>
  ({
    type: inferLiteral(types.SEND_CONFIRMATION_EMAIL_FAIL),
    payload,
  } as const);

export const setInitialValues = (payload: model.setInitialValuesPayload) =>
  ({
    type: inferLiteral(types.SET_INITIAL_VALUES),
    payload,
  } as const);

export const triggerMemberModal = () =>
  ({
    type: inferLiteral(types.TRIGGER_MEMBER_MODAL),
  } as const);
