import * as types from './types';

import { ContractActionTypes, CONTRACTS } from './model';
import { initialStore } from '../initial-store';

export const contractReducer = (state = initialStore.contracts, action: ContractActionTypes): CONTRACTS => {
  switch (action.type) {
    case types.GET_ALL_CONTRACTS: {
      return { ...state, isFetching: true };
    }
    case types.GET_ALL_CONTRACTS_SUCCESS: {
      return { ...state, contractList: action.payload.rows, count: action.payload.count, isFetching: false };
    }
    case types.GET_ALL_CONTRACTS_FAIL: {
      return { ...state, error: action.payload, isFetching: false };
    }
    case types.GET_CONTRACT_BY_ID: {
      return { ...state };
    }
    case types.GET_CONTRACT_BY_ID_SUCCESS: {
      return { ...state };
    }
    case types.GET_CONTRACT_BY_ID_FAIL: {
      return { ...state };
    }
    case types.CREATE_CONTRACT: {
      return { ...state };
    }
    case types.CREATE_CONTRACT_SUCCESS: {
      const { contract, limit, page } = action.payload;
      if (state.count < limit) {
        return {
          ...state,
          count: state.count + 1,
          contractList: [contract, ...state.contractList],
          modalOptions: { ...state.modalOptions, show: false },
        };
      }

      if (page === 1) {
        return {
          ...state,
          count: state.count + 1,
          contractList: [contract, ...state.contractList.slice(0, -1)],
          modalOptions: { ...state.modalOptions, show: false },
        };
      }

      return { ...state, count: state.count + 1, modalOptions: { ...state.modalOptions, show: false } };
    }
    case types.CREATE_CONTRACT_FAIL: {
      return { ...state, error: action.payload };
    }
    case types.UPDATE_CONTRACT: {
      return { ...state };
    }
    case types.UPDATE_CONTRACT_SUCCESS: {
      const { id } = action.payload;

      return {
        ...state,
        contractList: state.contractList.map((el) => (el.id === id ? action.payload : el)),
        modalOptions: { ...state.modalOptions, show: false },
      };
    }
    case types.UPDATE_CONTRACT_FAIL: {
      return { ...state, error: action.payload };
    }
    case types.REMOVE_CONTRACT: {
      return { ...state };
    }
    case types.REMOVE_CONTRACT_SUCCESS: {
      return {
        ...state,
        count: state.count - 1,
        contractList: state.contractList.filter((el) => el.id !== action.payload.id),
      };
    }
    case types.REMOVE_CONTRACT_FAIL: {
      return { ...state };
    }

    case types.SET_INITIAL_VALUES: {
      if (!action.payload)
        return {
          ...state,
          initialValues: initialStore.contracts.initialValues,
          updateContractId: '',
          modalOptions: { show: true, isUpdate: false },
        };

      const element = state.contractList.find((el) => el.id === action.payload);
      if (!element) return state;
      const { deadline, status, bid } = element;
      return {
        ...state,
        updateContractId: action.payload,
        initialValues: { deadline, status, bid },
        modalOptions: { show: true, isUpdate: true },
      };
    }

    case types.TRIGGER_CONTRACT_MODAL: {
      return { ...state, modalOptions: { ...state.modalOptions, show: false } };
    }

    case types.SET_QUERY_STRING: {
      const { filter } = action.payload;
      return { ...state, filter };
    }

    default:
      return state;
  }
};
