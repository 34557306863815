import React from 'react';

import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';

export interface SpinnerProps extends SpinProps {
  isVisible: boolean;
  children: React.ReactNode;
}

export const Spinner = ({ isVisible, children, ...props }: SpinnerProps) => {
  return (
    <Spin spinning={isVisible} {...props}>
      {children}
    </Spin>
  );
};
