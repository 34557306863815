import { lazy } from 'react';

import { URLS, AVAILABLE_ROLES, ADMIN_ROLE } from '../constants';

// TODO wnen you add new route, it should use lazy from React
export const ROUTES = [
  {
    id: 1,
    path: URLS.main,
    exact: true,
    layoutComponent: true,
    hidden: true,
    hasAccess: AVAILABLE_ROLES,
    component: lazy(() => import('./main')),
  },
  {
    id: 2,
    path: URLS.auth,
    exact: true,
    layoutComponent: false,
    hidden: false,
    hasAccess: [],
    component: lazy(() => import('./authenticate')),
  },
  {
    id: 3,
    path: URLS.profile,
    exact: true,
    layoutComponent: true,
    hidden: true,
    hasAccess: AVAILABLE_ROLES,
    component: lazy(() => import('./profile')),
  },
  {
    id: 4,
    path: URLS.admins,
    exact: true,
    layoutComponent: true,
    hidden: true,
    hasAccess: [ADMIN_ROLE],
    component: lazy(() => import('./admins-page')),
  },
  {
    id: 5,
    path: URLS.jobs,
    exact: true,
    layoutComponent: true,
    hidden: true,
    hasAccess: [ADMIN_ROLE],
    component: lazy(() => import('./job-page')),
  },
  {
    id: 6,
    path: URLS.members,
    exact: true,
    layoutComponent: true,
    hidden: true,
    hasAccess: [ADMIN_ROLE],
    component: lazy(() => import('./members')),
  },
  {
    id: 7,
    path: URLS.contracts,
    exact: true,
    layoutComponent: true,
    hidden: true,
    hasAccess: [ADMIN_ROLE],
    component: lazy(() => import('./contract-page')),
  },
  {
    id: 8,
    path: URLS.bids,
    exact: true,
    layoutComponent: true,
    hidden: true,
    hasAccess: [ADMIN_ROLE],
    component: lazy(() => import('./bid-page')),
  },
  {
    id: 9,
    path: URLS.feedbacks,
    exact: true,
    layoutComponent: true,
    hidden: false,
    hasAccess: [ADMIN_ROLE],
    component: lazy(() => import('./feedbacks-page')),
  },
  {
    id: 1005001,
    path: URLS.error,
    exact: true,
    layoutComponent: false,
    hidden: false,
    hasAccess: [],
    component: lazy(() => import('./error-page')),
  },
  {
    id: 1005002,
    exact: true,
    layoutComponent: false,
    hidden: false,
    hasAccess: [],
    component: lazy(() => import('./error-page')),
  },
];
