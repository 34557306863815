export const GET_ALL_CONTRACTS = 'contract/GET_ALL_CONTRACTS';
export const GET_ALL_CONTRACTS_SUCCESS = 'contract/GET_ALL_CONTRACTS_SUCCESS';
export const GET_ALL_CONTRACTS_FAIL = 'contract/GET_ALL_CONTRACTS_FAIL';
export const GET_CONTRACT_BY_ID = 'contract/GET_CONTRACT_BY_ID';
export const GET_CONTRACT_BY_ID_SUCCESS = 'contract/GET_CONTRACT_BY_ID_SUCCESS';
export const GET_CONTRACT_BY_ID_FAIL = 'contract/GET_CONTRACT_BY_ID_FAIL';
export const CREATE_CONTRACT = 'contract/CREATE_CONTRACT';
export const CREATE_CONTRACT_SUCCESS = 'contract/CREATE_CONTRACT_SUCCESS';
export const CREATE_CONTRACT_FAIL = 'contract/CREATE_CONTRACT_FAIL';
export const UPDATE_CONTRACT = 'contract/UPDATE_CONTRACT';
export const UPDATE_CONTRACT_SUCCESS = 'contract/UPDATE_CONTRACT_SUCCESS';
export const UPDATE_CONTRACT_FAIL = 'contract/UPDATE_CONTRACT_FAIL';
export const REMOVE_CONTRACT = 'contract/REMOVE_CONTRACT';
export const REMOVE_CONTRACT_SUCCESS = 'contract/REMOVE_CONTRACT_SUCCESS';
export const REMOVE_CONTRACT_FAIL = 'contract/REMOVE_CONTRACT_FAIL';

export const SET_INITIAL_VALUES = 'contract/SET_INITIAL_VALUES';
export const SET_QUERY_STRING = 'contract/SET_QUERY_STRING';

export const TRIGGER_CONTRACT_MODAL = 'contract/TRIGGER_CONTRACT_MODAL';
