import React, { memo } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

import './styles.scss';

type PickerProps = {
  value?: any;
  onChange?: (date: any, dateString: string) => void;
  onBlur?: () => void;
  placeholder?: string;
  className?: string;
  error?: string;
  touched?: boolean;
  disabledDate?: (date: moment.Moment) => boolean;
};

export const CustomDatePicker = memo(
  ({ value, onChange, onBlur, placeholder, className, touched, error, ...rest }: PickerProps) => {
    return (
      <div className={touched && error ? `pickerBox pickerBox__error-border ${className}` : `pickerBox ${className}`}>
        <DatePicker
          value={value ? moment(value) : undefined}
          onChange={onChange}
          placeholder={placeholder}
          className={'pickerBox__picker'}
          onBlur={onBlur}
          {...rest}
        />
        {touched && error && <span className="pickerBox__error">{error}</span>}
      </div>
    );
  }
);
