/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, takeEvery, put } from 'redux-saga/effects';

import * as types from '../../store/authenticate/types';
import * as actions from '../../store/authenticate/actions';
import { getCurrentAdminSuccess } from '../../store/admins/actions';

import {
  SignInSagas,
  SignUpSagas,
  LogOutSagas,
  RestorePasswordSagas,
  SignInSagasResponse,
  SessionRefreshSagas,
} from '../../store/authenticate/model';

import { API_URLS } from '../../constants';
import { http } from '../../utils/axios';
import { setToken, setRefreshToken } from '../../utils/localstage-actions';

function* signIn({ payload }: SignInSagas) {
  const encodedData = yield window.btoa(`${payload.email}:${payload.password}`);

  try {
    const response: SignInSagasResponse = yield call(http, {
      url: API_URLS.auth,
      method: 'POST',
      data: { access_token: process.env.REACT_APP_MASTER_KEY },
      headers: {
        Authorization: `Basic ${encodedData}`,
      },
    });
    yield setToken(response.data.token);
    yield setRefreshToken(response.data.refresh_token);

    yield put(actions.signInSuccess(response));
    yield put(getCurrentAdminSuccess(response.data.user));
  } catch (error) {
    yield put(actions.signInFail(error.message));
  }
}

function* signUp({ payload }: SignUpSagas) {
  try {
    const response = yield call(() => {});

    yield put(actions.signUpSuccess(response));
  } catch (error) {
    yield put(actions.signUpFail(error));
  }
}

function* logOut({ payload }: LogOutSagas) {
  try {
    const response = yield call(http, {
      url: API_URLS.logout,
      method: 'POST',
      data: payload,
    });

    yield put(actions.logOutSuccess({}));
    yield put(
      getCurrentAdminSuccess({
        id: '',
        name: '',
        email: '',
        picture: '',
        createdAt: '',
      })
    );
  } catch (error) {
    yield put(actions.logOutFail(error));
  }
}

function* sessionRefresh({ payload }: SessionRefreshSagas) {
  console.log('TCL: function*sessionRefresh -> payload', payload);
  try {
    const response = yield call(http, {
      url: API_URLS.refreshToken,
      method: 'POST',
      data: payload,
      headers: { Authorization: false },
    });
    yield setToken(response.data.access_token);
    yield setRefreshToken(response.data.refresh_token);
    yield put(actions.sessionRefreshSuccess({}));
  } catch (error) {
    yield put(actions.sessionRefreshFail(error));
  }
}

// function* responsePassword({ payload }: RestorePasswordSagas) {
//   try {
//     const response = yield call(() => {});

//     yield put(actions.restorePasswordSuccess(response));
//   } catch (error) {
//     yield put(actions.restorePasswordFail(error));
//   }
// }

function* auth() {
  yield takeEvery(types.SIGN_IN, signIn);
  yield takeEvery(types.SIGN_UP, signUp);
  yield takeEvery(types.LOG_OUT, logOut);
  yield takeEvery(types.SESSION_REFRESH, sessionRefresh);

  // yield takeEvery(types.RESTORE_PASSWORD, responsePassword);
}

export default auth;
