import { call, takeEvery, put } from 'redux-saga/effects';

import * as types from '../../store/commonInfo/types';
import * as actions from '../../store/commonInfo/actions';
import { commoninfoApi } from '../../api/commonInfoApi';

function* getAllCommonInfos() {
  try {
    const response = yield call(commoninfoApi.getAllCommonInfosRequest);

    yield put(actions.getAllCommonInfosSuccess(response));
  } catch (error) {
    yield put(actions.getAllCommonInfosFail(error.message));
  }
}

function* commoninfo() {
  yield takeEvery(types.GET_ALL_COMMONINFOS, getAllCommonInfos);
}

export default commoninfo;
