import React, { ReactNode } from 'react';
import { Popconfirm } from 'antd';

type PopconfirmType = {
  children: any;
  className?: string;
  cancelText?: string;
  okText?: string;
  okType?: 'link' | 'primary' | 'default' | 'ghost' | 'dashed' | 'danger';
  title: string | ReactNode;
  onCancel?: (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void;
  onConfirm: (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void;
  icon?: ReactNode;
  disabled?: boolean;
};

export const CustomPopconfirm = ({ children, className = '', title = '', ...rest }: PopconfirmType) => {
  return (
    <Popconfirm title={title} className={`popconfirm ${className}`} {...rest}>
      {children}
    </Popconfirm>
  );
};
