import axios, { AxiosRequestConfig } from 'axios';
import { openAxiosNotificationFail } from './show-notification';
import {
  getToken,
  removeToken,
  removeRefreshToken,
  setToken,
  setRefreshToken,
  getRefreshToken,
} from './localstage-actions';
import {
  API_URLS,
  ACCESS_TOKEN_EXPIRED,
  REFRESH_TOKEN_EXPIRED,
  REFRESH_TOKEN_INVALID,
  REFRESH_TOKEN_USED,
} from '../constants';

//REDUX
import store from '../store';
import { globalStoreReset } from '../store/global-reducer';

const instance = {
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 10000,
  headers: {
    'content-type': 'application/json',
  },
};

type RefreshCallback = (access_token: string) => void;
type RefreshTokenResponse = { access_token: string; refresh_token: string };

let refreshSubscribers: RefreshCallback[] = [];
let isRefreshingToken = false;

function onAccessTokenFetched(access_token: string) {
  refreshSubscribers.forEach((callback) => callback(access_token));
  refreshSubscribers = [];
}

function addSubscriber(callback: RefreshCallback) {
  refreshSubscribers.push(callback);
}

export const AxiosInstance = axios.create(instance);

AxiosInstance.interceptors.request.use(
  (config) => {
    // case for exclude Authorization property from request
    if (config.headers.Authorization === false) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { Authorization, ...rest } = config.headers;
      return {
        ...config,
        headers: { ...rest },
      };
    }
    // check for Authorization property
    if (config.headers.Authorization) return config;

    const token = getToken();
    return {
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${token}` },
    };
  },
  (error) => Promise.reject(error)
);

AxiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error && error.response && error.response.status !== 414) {
      switch (error.response.status) {
        case 401: {
          const originalRequest = error.config;

          if (
            error.response.data.message === REFRESH_TOKEN_EXPIRED ||
            error.response.data.message === REFRESH_TOKEN_INVALID ||
            error.response.data.message === REFRESH_TOKEN_USED
          ) {
            removeToken();
            removeRefreshToken();
            store.dispatch(globalStoreReset() as any);
            window.location.replace('/auth');
          }

          if (error.response.data.message !== ACCESS_TOKEN_EXPIRED) break;

          const retryOriginalRequest = new Promise((resolve) => {
            addSubscriber((access_token) => {
              originalRequest.headers.Authorization = 'Bearer ' + access_token;
              resolve(axios(originalRequest));
            });
          });

          if (originalRequest.url !== API_URLS.refreshToken && !isRefreshingToken) {
            isRefreshingToken = true;
            return AxiosInstance.post<RefreshTokenResponse>(
              API_URLS.refreshToken,
              {
                refresh_token: getRefreshToken(),
                expired_access_token: getToken(),
                access_token: process.env.REACT_APP_MASTER_KEY,
              },
              { headers: { Authorization: false } }
            )
              .then((res) => {
                isRefreshingToken = false;
                setToken(res.data.access_token);
                setRefreshToken(res.data.refresh_token);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + getToken();
                return res.data.access_token;
              })
              .then(onAccessTokenFetched);
          }

          return retryOriginalRequest;
        }
        default: {
          openAxiosNotificationFail(error);
        }
      }
    }
    return Promise.reject(error.response ? error : { ...error, response: 'Some internal error occurred' });
  }
);

export const http = async ({ url, method = 'GET', params, data, ...rest }: AxiosRequestConfig) => {
  return await AxiosInstance({ url, method, params, data, ...rest });
};
