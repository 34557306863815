import * as types from './types';
import * as model from './model';
import { inferLiteral } from './model';

export const setInitialValues = (payload: any) =>
  ({
    type: inferLiteral(types.SET_INITIAL_VALUES),
    payload,
  } as const);

export const triggerBidModal = () =>
  ({
    type: inferLiteral(types.TRIGGER_BID_MODAL),
  } as const);

export const getAllBids = (payload: model.getAllBidsPayload) =>
  ({
    type: inferLiteral(types.GET_ALL_BIDS),
    payload,
  } as const);

export const getAllBidSuccess = (payload: model.getAllBidSuccessPayload) =>
  ({
    type: inferLiteral(types.GET_ALL_BIDS_SUCCESS),
    payload,
  } as const);

export const getAllBidFail = (payload: model.getAllBidFailPayload) =>
  ({
    type: inferLiteral(types.GET_ALL_BIDS_FAIL),
    payload,
  } as const);

export const getCurrentBid = (payload: model.getCurrentBidPayload) =>
  ({
    type: inferLiteral(types.GET_BID_BY_ID),
    payload,
  } as const);

export const getCurrentBidSuccess = (payload: model.getCurrentBidSuccessPayload) =>
  ({
    type: inferLiteral(types.GET_BID_BY_ID_SUCCESS),
    payload,
  } as const);

export const getCurrentBidFail = (payload: model.getCurrentBidFailPayload) =>
  ({
    type: inferLiteral(types.GET_BID_BY_ID_FAIL),
    payload,
  } as const);

export const createBid = (payload: model.createBidPayload) =>
  ({
    type: inferLiteral(types.CREATE_BID),
    payload,
  } as const);

export const createBidSuccess = (payload: model.createBidSuccessPayload) =>
  ({
    type: inferLiteral(types.CREATE_BID_SUCCESS),
    payload,
  } as const);

export const createBidFail = (payload: model.createBidFailPayload) =>
  ({
    type: inferLiteral(types.CREATE_BID_FAIL),
    payload,
  } as const);

export const updateBid = (payload: model.updateBidPayload) =>
  ({
    type: inferLiteral(types.UPDATE_BID),
    payload,
  } as const);

export const updateBidSuccess = (payload: model.updateBidSuccessPayload) =>
  ({
    type: inferLiteral(types.UPDATE_BID_SUCCESS),
    payload,
  } as const);

export const updateBidFail = (payload: model.updateBidFailPayload) =>
  ({
    type: inferLiteral(types.UPDATE_BID_FAIL),
    payload,
  } as const);

export const removeBid = (payload: model.removeBidPayload) =>
  ({
    type: inferLiteral(types.REMOVE_BID),
    payload,
  } as const);

export const removeBidSuccess = (payload: model.removeBidSuccessPayload) =>
  ({
    type: inferLiteral(types.REMOVE_BID_SUCCESS),
    payload,
  } as const);

export const removeBidFail = (payload: model.removeBidFailPayload) =>
  ({
    type: inferLiteral(types.REMOVE_BID_FAIL),
    payload,
  } as const);
