export const GET_ALL_ADMINS = 'admin/GET_ALL_ADMINS';
export const GET_ALL_ADMINS_SUCCESS = 'admin/GET_ALL_ADMINS_SUCCESS';
export const GET_ALL_ADMINS_FAIL = 'admin/GET_ALL_ADMINS_FAIL';
export const CREATE_ADMIN = 'admin/CREATE_ADMIN';
export const CREATE_ADMIN_SUCCESS = 'admin/CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAIL = 'admin/CREATE_ADMIN_FAIL';
export const UPDATE_ADMIN = 'admin/UPDATE_ADMIN';
export const UPDATE_ADMIN_SUCCESS = 'admin/UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_FAIL = 'admin/UPDATE_ADMIN_FAIL';
export const REMOVE_ADMIN = 'admin/REMOVE_ADMIN';
export const REMOVE_ADMIN_SUCCESS = 'admin/REMOVE_ADMIN_SUCCESS';
export const REMOVE_ADMIN_FAIL = 'admin/REMOVE_ADMIN_FAIL';
export const GET_CURRENT_ADMIN = 'admin/GET_CURRENT_ADMIN';
export const GET_CURRENT_ADMIN_SUCCESS = 'admin/GET_CURRENT_ADMIN_SUCCESS';
export const GET_CURRENT_ADMIN_FAIL = 'admin/GET_CURRENT_ADMIN_FAIL';
export const SET_DEFAULT_PASSWORD = 'admin/SET_DEFAULT_PASSWORD';
export const SET_DEFAULT_PASSWORD_SUCCESS = 'admin/SET_DEFAULT_PASSWORD_SUCCESS';
export const SET_DEFAULT_PASSWORD_FAIL = 'admin/SET_DEFAULT_PASSWORD_FAIL';

export const SET_INITIAL_VALUES = 'admin/SET_INITIAL_VALUES';
