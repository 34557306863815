/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, takeEvery, put } from 'redux-saga/effects';

import * as types from '../../store/job/types';
import * as actions from '../../store/job/actions';

import {
  getAllJobsSagas,
  createJobSagas,
  updateJobSagas,
  removeJobSagas,
  getAllJobsResponse,
  createJobResponse,
  updateJobResponse,
} from '../../store/job/model';

import { API_URLS } from '../../constants';
import { http } from '../../utils/axios';
import { removeEmptyFields } from '../../utils/removeEmptyFields';

function* getAllJobs({ payload }: getAllJobsSagas) {
  try {
    const response: getAllJobsResponse = yield call(http, {
      url: API_URLS.jobs,
      params: payload,
    });

    yield put(actions.getAllJobSuccess(response.data));
  } catch (error) {
    yield put(actions.getAllJobFail(error.message));
  }
}

function* createJob({ payload }: createJobSagas) {
  const { values, limit, page } = payload;
  try {
    const response: createJobResponse = yield call(http, {
      url: API_URLS.jobs,
      method: 'POST',
      data: removeEmptyFields(values),
    });
    console.log('response', response);
    yield put(actions.createJobSuccess({ job: response.data, limit, page }));
  } catch (error) {
    yield put(actions.createJobFail(error.message));
  }
}

function* updateJob({ payload }: updateJobSagas) {
  try {
    const response: updateJobResponse = yield call(http, {
      url: `${API_URLS.jobs}/${payload.id}`,
      method: 'PUT',
      data: removeEmptyFields(payload),
    });

    yield put(actions.updateJobSuccess(response.data));
  } catch (error) {
    yield put(actions.updateJobFail(error));
  }
}

function* removeJob({ payload }: removeJobSagas) {
  try {
    yield call(http, {
      url: `${API_URLS.jobs}/${payload.id}`,
      method: 'DELETE',
    });
    yield put(actions.removeJobSuccess({ id: payload.id }));
  } catch (error) {
    yield put(actions.removeJobFail(error.message));
  }
}

function* job() {
  yield takeEvery(types.GET_ALL_JOBS, getAllJobs);
  yield takeEvery(types.CREATE_JOB, createJob);
  yield takeEvery(types.UPDATE_JOB, updateJob);
  yield takeEvery(types.REMOVE_JOB, removeJob);
}

export default job;
