import React from 'react';
import { Table, Empty } from 'antd';
import { PaginationConfig, PaginationProps } from 'antd/lib/pagination';
import { SorterResult, TableCurrentDataSource, Key } from 'antd/lib/table/interface';

export type changeTableEvent = (
  pagination: PaginationConfig,
  filters: Record<string, Key[] | null>,
  sorter: SorterResult<{}> | SorterResult<{}>[],
  extra: TableCurrentDataSource<{}>
) => void;

interface TableTypes {
  changeTableEvent?: changeTableEvent;
  data: any;
  columns: any;
  rowKey?: string;
  loading?: boolean;
  pagination?: PaginationProps | false;
  scroll?:
    | ({ x?: string | number | true | undefined; y?: string | number | undefined } & {
        scrollToFirstRowOnChange?: boolean | undefined;
      })
    | undefined;
  className?: string;
  bordered?: boolean;
}

export const CustomTable = ({
  loading = false,
  changeTableEvent,
  data = undefined,
  columns,
  rowKey = 'id',
  pagination = undefined,
  scroll,
  className,
  bordered,
}: TableTypes) => {
  return (
    <Table
      locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
      loading={loading}
      onChange={changeTableEvent as any}
      dataSource={data}
      columns={columns}
      rowKey={rowKey}
      pagination={pagination}
      scroll={scroll}
      className={className}
      bordered={bordered}
    />
  );
};
