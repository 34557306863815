// DEPENDENCIES
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dropdown, Menu } from 'antd';

// MODELS
import { STORE } from '../../store/model';
import { SingleAdmin } from '../../store/admins/model';
import { globalStoreReset } from '../../store/global-reducer';
import { LogOutType } from '../../store/authenticate/model';

// CONSTANTS
import { URLS } from '../../constants';

// ACTION CREATORS
// import { globalStoreReset } from '../../store/global/actions';
import { logOut } from '../../store/authenticate/actions';

// UTILS
import { removeToken, removeRefreshToken, getToken, getRefreshToken } from '../../utils/localstage-actions';

// COMPONENTS
import { CustomAvatar } from '../../components';

// STYLES
import './styles.scss';

interface OwnProps {}

interface StoreProps {
  admin: SingleAdmin;
}

interface DispatchProps {
  globalStoreReset: () => void;
  logOut: LogOutType;
}

type DesktopHeaderProps = OwnProps & StoreProps & DispatchProps & RouteComponentProps;

class Header extends Component<DesktopHeaderProps> {
  performLogOut = () => {
    const { history, globalStoreReset, logOut } = this.props;
    const accessToken = getToken();
    const refreshToken = getRefreshToken();
    removeToken();
    removeRefreshToken();
    accessToken && refreshToken && logOut({ accessToken, refreshToken });
    globalStoreReset();

    history.push(URLS.auth);
  };

  redirect = (url: string) => {
    const { history } = this.props;

    history.push(url);
  };

  render() {
    const { admin } = this.props;

    const menu = (
      <Menu>
        <Menu.Item onClick={this.redirect.bind(null, URLS.profile)}>
          <span>Edit profile</span>
        </Menu.Item>
        <Menu.Item onClick={this.performLogOut}>
          <span>Log Out</span>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="header">
        <div className="header__links-list header__links-list--side">
          <p className="header__user-email">{`Hello, ${admin.name}`}</p>
          <Dropdown overlay={menu}>
            <div>
              <CustomAvatar className="header__avatar" src={admin.picture} size={40} />
            </div>
          </Dropdown>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: STORE) => ({
  admin: state.admins.currentAdmin,
});

export const CustomHeader = connect<StoreProps, DispatchProps, OwnProps, STORE>(mapStateToProps, {
  globalStoreReset,
  logOut,
})(Header);
