import jwtDecode from 'jwt-decode';

import { getToken } from './localstage-actions';

export const checkJWT = (): [string, string] => {
  const token = getToken();
  if (!token) return ['', ''];

  const { id, role } = jwtDecode(token);
  return [id, role];
};
