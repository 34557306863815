import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getAllCommonInfos } from '../../store/commonInfo/actions';
import { getAllCommonInfosType } from '../../store/commonInfo/model';
import { STORE } from '../../store/model';

type OwnProps = {
  children: React.ReactNode;
};

type StoreProps = {};

interface DispatchProps {
  getAllCommonInfos: getAllCommonInfosType;
}

type AllProps = OwnProps & StoreProps & DispatchProps;

const CommonLayout = ({ children, getAllCommonInfos }: AllProps) => {
  useEffect(() => {
    getAllCommonInfos({});
  }, []);

  return <>{children}</>;
};

export const CommonInfoLayout = connect<StoreProps, DispatchProps, OwnProps, STORE>(null, {
  getAllCommonInfos,
})(CommonLayout);
