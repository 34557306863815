import { http } from '../utils/axios';

import { API_URLS } from '../constants';
import {
  getAllMembersPayload,
  removeMemberPayload,
  createMemberPayload,
  updateMemberPayload,
  setDefaultPasswordPayload,
  sendConfirmationEmailPayload,
} from '../store/member/model';

export const membersApi = {
  getAllMembersRequest(params: getAllMembersPayload) {
    return http({ url: API_URLS.members, params }).then((res) => res.data);
  },
  createMemberRequest(payload: createMemberPayload['values']) {
    return http({ url: API_URLS.members, method: 'POST', data: { ...payload } }).then((res) => res.data);
  },
  updateMemberRequest({ id, values }: { values: updateMemberPayload; id: string }) {
    return http({ url: `${API_URLS.members}/${id}`, method: 'PUT', data: { ...values } }).then((res) => res.data);
  },
  removeMemberRequest(payload: removeMemberPayload) {
    return http({ url: `${API_URLS.members}/${payload.id}`, method: 'DELETE' }).then((res) => res.data);
  },
  setDefaultPasswordRequest(payload: setDefaultPasswordPayload) {
    const { id, email } = payload;
    return http({ method: 'PUT', url: `${API_URLS.setDefaultMemberPassword}/${id}`, data: { email } }).then(
      (res) => res.data
    );
  },
  sendConfirmationEmailRequest({ id }: sendConfirmationEmailPayload) {
    return http({ url: `${API_URLS.resendConfirmationEmailToMember}/${id}` }).then((res) => res.data);
  },
};
