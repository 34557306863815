export const GET_ALL_JOBS = 'job/GET_ALL_JOBS';
export const GET_ALL_JOBS_SUCCESS = 'job/GET_ALL_JOBS_SUCCESS';
export const GET_ALL_JOBS_FAIL = 'job/GET_ALL_JOBS_FAIL';
export const CREATE_JOB = 'job/CREATE_JOB';
export const CREATE_JOB_SUCCESS = 'job/CREATE_JOB_SUCCESS';
export const CREATE_JOB_FAIL = 'job/CREATE_JOB_FAIL';
export const UPDATE_JOB = 'job/UPDATE_JOB';
export const UPDATE_JOB_SUCCESS = 'job/UPDATE_JOB_SUCCESS';
export const UPDATE_JOB_FAIL = 'job/UPDATE_JOB_FAIL';
export const REMOVE_JOB = 'job/REMOVE_JOB';
export const REMOVE_JOB_SUCCESS = 'job/REMOVE_JOB_SUCCESS';
export const REMOVE_JOB_FAIL = 'job/REMOVE_JOB_FAIL';
export const GET_CURRENT_JOB = 'job/GET_CURRENT_JOB';
export const GET_CURRENT_JOB_SUCCESS = 'job/GET_CURRENT_JOB_SUCCESS';
export const GET_CURRENT_JOB_FAIL = 'job/GET_CURRENT_JOB_FAIL';
export const SET_DEFAULT_PASSWORD = 'job/SET_DEFAULT_PASSWORD';
export const SET_DEFAULT_PASSWORD_SUCCESS = 'job/SET_DEFAULT_PASSWORD_SUCCESS';
export const SET_DEFAULT_PASSWORD_FAIL = 'job/SET_DEFAULT_PASSWORD_FAIL';

export const SET_INITIAL_VALUES = 'job/SET_INITIAL_VALUES';
