import * as types from './types';
import * as model from './model';
import { inferLiteral } from './model';

export const getAllJobs = (payload: model.getAllJobsPayload) =>
  ({
    type: inferLiteral(types.GET_ALL_JOBS),
    payload,
  } as const);

export const getAllJobSuccess = (payload: model.getAllJobSuccessPayload) =>
  ({
    type: inferLiteral(types.GET_ALL_JOBS_SUCCESS),
    payload,
  } as const);

export const getAllJobFail = (payload: model.getAllJobFailPayload) =>
  ({
    type: inferLiteral(types.GET_ALL_JOBS_FAIL),
    payload,
  } as const);

export const createJob = (payload: model.createJobPayload) =>
  ({
    type: inferLiteral(types.CREATE_JOB),
    payload,
  } as const);

export const createJobSuccess = (payload: model.createJobSuccessPayload) =>
  ({
    type: inferLiteral(types.CREATE_JOB_SUCCESS),
    payload,
  } as const);

export const createJobFail = (payload: model.createJobFailPayload) =>
  ({
    type: inferLiteral(types.CREATE_JOB_FAIL),
    payload,
  } as const);

export const updateJob = (payload: model.updateJobPayload) =>
  ({
    type: inferLiteral(types.UPDATE_JOB),
    payload,
  } as const);

export const updateJobSuccess = (payload: model.updateJobSuccessPayload) =>
  ({
    type: inferLiteral(types.UPDATE_JOB_SUCCESS),
    payload,
  } as const);

export const updateJobFail = (payload: model.updateJobFailPayload) =>
  ({
    type: inferLiteral(types.UPDATE_JOB_FAIL),
    payload,
  } as const);

export const removeJob = (payload: model.removeJobPayload) =>
  ({
    type: inferLiteral(types.REMOVE_JOB),
    payload,
  } as const);

export const removeJobSuccess = (payload: model.removeJobSuccessPayload) =>
  ({
    type: inferLiteral(types.REMOVE_JOB_SUCCESS),
    payload,
  } as const);

export const removeJobFail = (payload: model.removeJobFailPayload) =>
  ({
    type: inferLiteral(types.REMOVE_JOB_FAIL),
    payload,
  } as const);

export const getCurrentJob = () =>
  ({
    type: inferLiteral(types.GET_CURRENT_JOB),
  } as const);

export const getCurrentJobSuccess = (payload: model.getCurrentJobSuccessPayload) =>
  ({
    type: inferLiteral(types.GET_CURRENT_JOB_SUCCESS),
    payload,
  } as const);

export const getCurrentJobFail = (payload: model.getCurrentJobFailPayload) =>
  ({
    type: inferLiteral(types.GET_CURRENT_JOB_FAIL),
    payload,
  } as const);

export const setDefaultPassword = (payload: model.setDefaultPasswordPayload) =>
  ({
    type: inferLiteral(types.SET_DEFAULT_PASSWORD),
    payload,
  } as const);

export const setDefaultPasswordSuccess = () =>
  ({
    type: inferLiteral(types.SET_DEFAULT_PASSWORD_SUCCESS),
  } as const);

export const setDefaultPasswordFail = (payload: model.setDefaultPasswordFailPayload) =>
  ({
    type: inferLiteral(types.SET_DEFAULT_PASSWORD_FAIL),
    payload,
  } as const);

export const setInitialValues = (payload?: model.setInitialValuesPayload) =>
  ({
    type: inferLiteral(types.SET_INITIAL_VALUES),
    payload,
  } as const);
