import { http } from '../utils/axios';

import {
  getAllBidsPayload,
  createBidPayload,
  updateBidPayload,
  removeBidPayload,
  getCurrentBidPayload,
} from '../store/bid/model';
import { API_URLS } from '../constants';

// const fakeRequest = () => new Promise((resolve) => setTimeout(() => resolve([]), 1000));

export const bidApi = {
  getAllBidsRequest(payload: getAllBidsPayload) {
    return http({ url: API_URLS.bids, params: { ...payload } }).then((res) => res.data);
  },
  getCurrentBidRequest(payload: getCurrentBidPayload) {
    return http({ url: `${API_URLS.bids}/${payload.id}` }).then((res) => res.data);
  },
  createBidRequest(payload: createBidPayload) {
    return http({ method: 'POST', url: API_URLS.bids, data: payload }).then((res) => res.data);
  },
  updateBidRequest(payload: updateBidPayload) {
    return http({ method: 'PUT', url: `${API_URLS.bids}/${payload.id}`, data: payload.bid }).then((res) => res.data);
  },
  removeBidRequest(payload: removeBidPayload) {
    return http({ method: 'DELETE', url: `${API_URLS.bids}/${payload.id}` }).then((res) => res.data);
  },
};
