// getAllMembers
export const GET_ALL_MEMBERS = 'member/GET_ALL_MEMBERS';
export const GET_ALL_MEMBERS_SUCCESS = 'member/GET_ALL_MEMBERS_SUCCESS';
export const GET_ALL_MEMBERS_FAIL = 'member/GET_ALL_MEMBERS_FAIL';

// createMember
export const CREATE_MEMBER = 'member/CREATE_MEMBER';
export const CREATE_MEMBER_SUCCESS = 'member/CREATE_MEMBER_SUCCESS';
export const CREATE_MEMBER_FAIL = 'member/CREATE_MEMBER_FAIL';
// updateMember
export const UPDATE_MEMBER = 'member/UPDATE_MEMBER';
export const UPDATE_MEMBER_SUCCESS = 'member/UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_FAIL = 'member/UPDATE_MEMBER_FAIL';
// removeMember
export const REMOVE_MEMBER = 'member/REMOVE_MEMBER';
export const REMOVE_MEMBER_SUCCESS = 'member/REMOVE_MEMBER_SUCCESS';
export const REMOVE_MEMBER_FAIL = 'member/REMOVE_MEMBER_FAIL';

// setDefaultPassword
export const SET_DEFAULT_PASSWORD = 'member/SET_DEFAULT_PASSWORD';
export const SET_DEFAULT_PASSWORD_SUCCESS = 'member/SET_DEFAULT_PASSWORD_SUCCESS';
export const SET_DEFAULT_PASSWORD_FAIL = 'member/SET_DEFAULT_PASSWORD_FAIL';

// resendConfirmationEmail
export const SEND_CONFIRMATION_EMAIL = 'member/SEND_CONFIRMATION_EMAIL';
export const SEND_CONFIRMATION_EMAIL_SUCCESS = 'member/SEND_CONFIRMATION_EMAIL_SUCCESS';
export const SEND_CONFIRMATION_EMAIL_FAIL = 'member/SEND_CONFIRMATION_EMAIL_FAIL';

export const SET_INITIAL_VALUES = 'member/SET_INITIAL_VALUES';
export const TRIGGER_MEMBER_MODAL = 'member/TRIGGER_MEMBER_MODAL';
