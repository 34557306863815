import * as types from './types';
import { MemberActionTypes, MEMBERS, SingleMember } from './model';

import { initialStore } from '../initial-store';
import { IMPLEMENTER } from '../../constants';

export const memberReducer = (state = initialStore.members, action: MemberActionTypes): MEMBERS => {
  switch (action.type) {
    case types.GET_ALL_MEMBERS: {
      return { ...state, isFetching: true };
    }
    case types.GET_ALL_MEMBERS_SUCCESS: {
      const {
        response: { count, rows },
        type,
      } = action.payload;
      if (!type) {
        const membersSelectOptions = rows.map((el: SingleMember) => {
          return { value: el.id, title: `${el.firstName}, ${el.email}` };
        });

        return {
          ...state,
          isFetching: false,
          count,
          listOfMembers: rows,
          membersSelectOptions,
          implementerSelectOptions: [],
          purchaserSelectOptions: [],
        };
      }

      const { implementerSelectOptions, purchaserSelectOptions } = rows.reduce(
        (acc, member) => {
          if (member.type === IMPLEMENTER) {
            return {
              ...acc,
              implementerSelectOptions: [
                ...acc.implementerSelectOptions,
                {
                  value: member.id,
                  title: `${member.firstName}, ${member.email}`,
                },
              ],
            };
          } else {
            return {
              ...acc,
              purchaserSelectOptions: [
                ...acc.purchaserSelectOptions,
                {
                  value: member.id,
                  title: `${member.firstName}, ${member.email}`,
                },
              ],
            };
          }
        },
        {
          implementerSelectOptions: [{ value: '', title: 'All' }],
          purchaserSelectOptions: [{ value: '', title: 'All' }],
        }
      );

      return {
        ...state,
        isFetching: false,
        count,
        listOfMembers: rows,
        implementerSelectOptions,
        purchaserSelectOptions,
        membersSelectOptions: [],
      };
    }
    case types.GET_ALL_MEMBERS_FAIL: {
      return { ...state, isFetching: false, error: action.payload };
    }

    case types.CREATE_MEMBER: {
      return { ...state };
    }
    case types.CREATE_MEMBER_SUCCESS: {
      const { member, limit, page } = action.payload;
      if (state.count < limit) {
        return {
          ...state,
          count: state.count + 1,
          listOfMembers: [member, ...state.listOfMembers],
          modalOptions: { ...state.modalOptions, show: false },
        };
      }

      if (page === 1) {
        return {
          ...state,
          count: state.count + 1,
          listOfMembers: [member, ...state.listOfMembers.slice(0, -1)],
          modalOptions: { ...state.modalOptions, show: false },
        };
      }
      return { ...state, count: state.count + 1, modalOptions: { ...state.modalOptions, show: false } };
    }
    case types.CREATE_MEMBER_FAIL: {
      return { ...state, error: action.payload };
    }

    case types.UPDATE_MEMBER: {
      return { ...state };
    }
    case types.UPDATE_MEMBER_SUCCESS: {
      const { id } = action.payload;
      return {
        ...state,
        listOfMembers: state.listOfMembers.map((el) => (el.id === id ? action.payload : el)),
        modalOptions: { ...state.modalOptions, show: false },
      };
    }
    case types.UPDATE_MEMBER_FAIL: {
      return { ...state, error: action.payload };
    }

    case types.REMOVE_MEMBER: {
      return { ...state };
    }
    case types.REMOVE_MEMBER_SUCCESS: {
      return {
        ...state,
        count: state.count - 1,
        listOfMembers: state.listOfMembers.filter((el) => el.id !== action.payload.id),
      };
    }
    case types.REMOVE_MEMBER_FAIL: {
      return { ...state };
    }

    case types.SET_DEFAULT_PASSWORD: {
      return { ...state };
    }
    case types.SET_DEFAULT_PASSWORD_SUCCESS: {
      return { ...state };
    }
    case types.SET_DEFAULT_PASSWORD_FAIL: {
      return { ...state, error: action.payload };
    }

    case types.SEND_CONFIRMATION_EMAIL: {
      return { ...state };
    }
    case types.SEND_CONFIRMATION_EMAIL_SUCCESS: {
      return { ...state };
    }
    case types.SEND_CONFIRMATION_EMAIL_FAIL: {
      return { ...state, error: action.payload };
    }

    case types.SET_INITIAL_VALUES: {
      if (action.payload) {
        const member = state.listOfMembers.find((el) => el.id === action.payload);
        if (!member) return { ...state };

        const { firstName, lastName, email, avatar, type, confirmed } = member;
        return {
          ...state,
          initialValues: { type, firstName, lastName, email, avatar, confirmed },
          updateMemberId: action.payload,
          modalOptions: { show: true, isUpdate: true },
        };
      }
      return {
        ...state,
        initialValues: initialStore.members.initialValues,
        updateMemberId: '',
        modalOptions: { show: true, isUpdate: false },
      };
    }

    case types.TRIGGER_MEMBER_MODAL: {
      return { ...state, modalOptions: { ...state.modalOptions, show: false } };
    }

    default:
      return state;
  }
};
