import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';

type NOTIFICATION = { message: string; description?: string };

const descriptionCreator = (resp: AxiosResponse) => {
  if (!resp.data) return '';

  if (typeof resp.data === 'string') return resp.data;

  return resp.data.message;
};

export const openNotificationFail = (notificationData: NOTIFICATION) => {
  notification.error(notificationData);
};

export const openAxiosNotificationFail = (data: AxiosError) => {
  const { message = '' } = data;

  openNotificationFail({
    message: message,
    description: data.response ? descriptionCreator(data.response) : '',
  });
};

export const openNotificationSuccess = (notificationData: NOTIFICATION) => {
  notification.success(notificationData);
};

export const openAxiosSuccessNotification = ({ data }: AxiosResponse) => {
  const { message = '' } = data;

  openNotificationSuccess({
    message: message,
    description: data.response ? descriptionCreator(data.response) : '',
  });
};
