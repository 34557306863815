import { combineReducers, Reducer } from 'redux';

import { STORE } from './model';
import { AuthActionTypes } from './authenticate/model';
import { AdminActionTypes } from './admins/model';
import { MemberActionTypes } from './member/model';
import { JobActionTypes } from './job/model';

import { initialStore } from './initial-store';

// reducers
import { authReducer } from './authenticate/reducer';
import { adminsReducer } from './admins/reducer';
import { jobReducer } from './job/reducer';
import { contractReducer } from './contract/reducer';
import { memberReducer } from './member/reducer';
import { bidReducer } from './bid/reducer';
import { commoninfoReducer } from './commonInfo/reducer';

const appReducer = combineReducers<STORE, AppActions>({
  auth: authReducer,
  admins: adminsReducer,
  jobs: jobReducer,
  contracts: contractReducer,
  members: memberReducer,
  bids: bidReducer,
  commonInfos: commoninfoReducer,
});

export type AppActions = AdminActionTypes & AuthActionTypes & MemberActionTypes & JobActionTypes;

const GLOBAL_STORE_RESET = 'global/GLOBAL_STORE_RESET';

export const globalStoreReset = () =>
  ({
    type: GLOBAL_STORE_RESET,
  } as const);

export const rootReducer: Reducer<STORE, AppActions> = (state, action): STORE => {
  if (action.type === GLOBAL_STORE_RESET) {
    return initialStore;
  }

  return appReducer(state, action);
};
