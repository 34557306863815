import { http } from '../utils/axios';

import {
  getAllAdminsPayload,
  createAdminPayload,
  updateAdminPayload,
  removeAdminPayload,
  setDefaultPasswordPayload,
} from '../store/admins/model';
import { API_URLS } from '../constants';

const fakeRequest = () => new Promise((resolve) => setTimeout(() => resolve([]), 1000));

export const adminsApi = {
  getAllAdminsRequest(payload: getAllAdminsPayload) {
    return http({ url: API_URLS.admins, params: { ...payload } }).then((res) => res.data);
  },
  getAdminByIdRequest() {
    return fakeRequest;
  },
  createAdminRequest(payload: createAdminPayload['values']) {
    return http({ method: 'POST', url: API_URLS.admins, data: payload }).then((res) => res.data);
  },
  updateAdminRequest(payload: updateAdminPayload) {
    return http({ method: 'PUT', url: `${API_URLS.admins}/${payload.id}`, data: payload }).then((res) => res.data);
  },
  removeAdminRequest(payload: removeAdminPayload) {
    return http({ method: 'DELETE', url: `${API_URLS.admins}/${payload.id}` }).then((res) => res.data);
  },
  getCurrentAdminRequest() {
    return http({ url: API_URLS.currentAdmin }).then((res) => res.data);
  },
  setDefaultPasswordRequest(payload: setDefaultPasswordPayload) {
    const { id, email } = payload;
    return http({ method: 'PUT', url: `${API_URLS.setDefaultAdminPassword}/${id}`, data: { email } }).then(
      (res) => res.data
    );
  },
};
