import React, { useMemo, useState } from 'react';
import {
  AlphaPicker,
  BlockPicker,
  ChromePicker,
  CirclePicker,
  CompactPicker,
  GithubPicker,
  HuePicker,
  MaterialPicker,
  PhotoshopPicker,
  SketchPicker,
  SwatchesPicker,
  TwitterPicker,
  AlphaPickerProps,
  BlockPickerProps,
  ChromePickerProps,
  CirclePickerProps,
  CompactPickerProps,
  GithubPickerProps,
  HuePickerProps,
  MaterialPickerProps,
  PhotoshopPickerProps,
  SketchPickerProps,
  SwatchesPickerProps,
  TwitterPickerProps,
  HSLColor,
  RGBColor,
} from 'react-color';
import './style.scss';

export enum PickerType {
  ALFA = 'alfa',
  BLOCK = 'block',
  CHROME = 'chrome',
  CIRCLE = 'circle',
  COMPACT = 'compact',
  GITHUB = 'github',
  HUE = 'hue',
  MATERIAL = 'material',
  PHOTOSHOP = 'photoshop',
  SKETCH = 'sketch',
  SWATCHES = 'swatches',
  TWITTER = 'twitter',
}

type ColorTypes = {
  hex?: string;
  hsl?: HSLColor;
  rgb?: RGBColor;
};

type PickerProps =
  | AlphaPickerProps
  | BlockPickerProps
  | ChromePickerProps
  | CirclePickerProps
  | CompactPickerProps
  | GithubPickerProps
  | HuePickerProps
  | MaterialPickerProps
  | PhotoshopPickerProps
  | SketchPickerProps
  | SwatchesPickerProps
  | TwitterPickerProps
  | { [key: string]: any };

type Props = {
  label?: string;
  isRequired?: boolean;
  error?: boolean;
  disabled?: boolean;
  color?: ColorTypes;
  type?: PickerType;
  handleChangeComplete?: (data: ColorTypes, event: MouseEvent) => void;
  handleChange?: (data: ColorTypes, event: MouseEvent) => void;
  config?: PickerProps;
};

const pickerModule = {
  [PickerType.ALFA]: (props: AlphaPickerProps) => <AlphaPicker {...props} />,
  [PickerType.BLOCK]: (props: BlockPickerProps) => <BlockPicker {...props} />,
  [PickerType.CHROME]: (props: ChromePickerProps) => <ChromePicker {...props} />,
  [PickerType.CIRCLE]: (props: CirclePickerProps) => <CirclePicker {...props} />,
  [PickerType.COMPACT]: (props: CompactPickerProps) => <CompactPicker {...props} />,
  [PickerType.GITHUB]: (props: GithubPickerProps) => <GithubPicker {...props} />,
  [PickerType.HUE]: (props: HuePickerProps) => <HuePicker {...props} />,
  [PickerType.MATERIAL]: (props: MaterialPickerProps) => <MaterialPicker {...props} />,
  [PickerType.PHOTOSHOP]: (props: PhotoshopPickerProps) => <PhotoshopPicker {...props} />,
  [PickerType.SKETCH]: (props: SketchPickerProps) => <SketchPicker {...props} />,
  [PickerType.SWATCHES]: (props: SwatchesPickerProps) => <SwatchesPicker {...props} />,
  [PickerType.TWITTER]: (props: TwitterPickerProps) => <TwitterPicker {...props} />,
};

export const ColorPicker = ({
  color,
  label = '',
  isRequired = false,
  error = false,
  disabled = false,
  handleChangeComplete = () => {},
  handleChange = () => {},
  type = PickerType.SKETCH,
  config = {},
}: Props) => {
  const [showPalette, toggleShowPalette] = useState(false);
  const [background, setBackground] = useState(color);

  const Picker = useMemo((): any => pickerModule[type], [type]);

  const changeComplete = (color: ColorTypes, event: MouseEvent) => {
    setBackground(color);
    handleChangeComplete && handleChangeComplete(color, event);
  };

  const getBackground = () => {
    if (background) {
      if (background.rgb) {
        return `rgba(${background.rgb.r}, ${background.rgb.g}, ${background.rgb.b}, ${background.rgb.a})`;
      } else if (background.hex) {
        return background.hex;
      }

      return '#fff';
    }

    return '#fff';
  };

  return (
    <div>
      {label && (
        <>
          <label className="label">
            <span className={`span-text span-text__with-margin${disabled ? ' span-text__disabled' : ''}`}>
              {label}
              {isRequired && <span>*</span>}
            </span>
          </label>
        </>
      )}
      <div className="picker__wrapper">
        {showPalette && (
          <div className="picker">
            <div className="picker__back-div" onClick={() => toggleShowPalette(!showPalette)} />
            <Picker
              color={background ? background.rgb || background.hex : '#fff'}
              onChangeComplete={changeComplete}
              onChange={handleChange}
              {...config}
            />
          </div>
        )}
        <button
          className={`action-btn${error ? ' action-btn__error' : ''}`}
          onClick={() => toggleShowPalette(!showPalette)}
          disabled={disabled}
        >
          <div
            className="action-btn__preview"
            style={{
              backgroundColor: getBackground(),
            }}
          />
        </button>
      </div>
    </div>
  );
};
