export const URLS = {
  main: '/',
  profile: '/profile',
  auth: '/auth',
  error: '/error',
  admins: '/admins',
  jobs: '/jobs',
  contracts: '/contracts',
  members: '/members',
  feedbacks: '/feedbacks',
  bids: '/bids',
  styleGuide: '/style-guide',
};

export const API_URLS = {
  auth: '/auth',
  currentAdmin: '/admins/me',
  admins: '/admins',
  members: '/members',
  checkMembersEmail: '/members/checkEmail',
  jobs: '/jobs',
  contracts: '/contracts',
  setDefaultPassword: '/admin/setDefaultPassword/',
  checkUserEmail: '/admins/checkEmail',
  bids: '/bids',
  commonInfo: '/common-info',
  checkAdminsEmail: '/admins/checkEmail',
  setDefaultAdminPassword: '/admins/setDefaultPassword',
  setDefaultMemberPassword: '/members/setDefaultPassword',
  resendConfirmationEmailToMember: 'members/resendConfirmationEmail',
  refreshToken: '/auth/refresh',
  logout: 'auth/logout',
};

export const ADMIN_ROLE = 'admin';
export const USER_ROLE = 'user';
export const AVAILABLE_ROLES = [ADMIN_ROLE, USER_ROLE];

// PAGINATION
export const DEFAULT_QUERY_PROP_ADMINS_PAGE = {
  page: 1,
  limit: 10,
  sort: '',
};

export const DEFAULT_QUERY_PROP_JOBS_PAGE = {
  page: 1,
  limit: 10,
  sort: '',
};

export const DEFAULT_QUERY_PROP_FEEDBACKS_PAGE = {
  page: 1,
  limit: 5,
  sort: '',
};

export const DEFAULT_QUERY_PROP_CONTRACTS_PAGE = {
  page: 1,
  limit: 10,
  sort: '',
};

export const DEFAULT_QUERY_PROP_BID_PAGE = {
  page: 1,
  limit: 10,
  sort: '',
};

export const DEFAULT_QUERY_PROP_MEMBERS_PAGE = {
  page: 1,
  limit: 10,
  sort: '',
};

export const ACCESS_TOKEN_EXPIRED = 'jwt_expired';
export const REFRESH_TOKEN_EXPIRED = 'refresh_jwt_expired';
export const REFRESH_TOKEN_INVALID = 'invalid_refresh_token';
export const REFRESH_TOKEN_USED = 'used_refresh_token';

export const IMPLEMENTER = 'implementer';
export const PURCHASER = 'purchaser';

export const ALL_TYPES = [IMPLEMENTER, PURCHASER];

export const S3_BUCKET_DOMAIN_URL = 'https://twsgo-files.s3.eu-central-1.amazonaws.com/';
