import React from 'react';
import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';

interface AllProps extends TagProps {
  children: React.ReactNode;
}

export const CustomTag = ({ children, ...rest }: AllProps) => {
  return <Tag {...rest}>{children}</Tag>;
};
