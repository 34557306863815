import * as types from './types';

import { BidActionTypes, BIDS, Bid } from './model';
import { initialStore } from '../initial-store';

export const bidReducer = (state = initialStore.bids, action: BidActionTypes): BIDS => {
  switch (action.type) {
    case types.GET_ALL_BIDS: {
      return { ...state, isFetching: true };
    }
    case types.GET_ALL_BIDS_SUCCESS: {
      const bidsSelectOptions = action.payload.rows.map((el: Bid) => {
        return { value: el.id, title: `${el.desc}` };
      });
      return { ...state, isFetching: false, error: '', bidList: action.payload, bidsSelectOptions };
    }
    case types.GET_ALL_BIDS_FAIL: {
      return { ...state, isFetching: false, error: action.payload };
    }
    case types.GET_BID_BY_ID: {
      return { ...state };
    }
    case types.GET_BID_BY_ID_SUCCESS: {
      return { ...state };
    }
    case types.GET_BID_BY_ID_FAIL: {
      return { ...state };
    }
    case types.CREATE_BID: {
      return { ...state, isFetching: true };
    }
    case types.CREATE_BID_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: '',
        bidList: { count: state.bidList.count + 1, rows: state.bidList.rows.concat(action.payload) },
        modalOptions: { ...state.modalOptions, show: false },
      };
    }
    case types.CREATE_BID_FAIL: {
      return { ...state, isFetching: false, error: action.payload };
    }
    case types.UPDATE_BID: {
      return { ...state, isFetching: true };
    }
    case types.UPDATE_BID_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: '',
        bidList: {
          count: state.bidList.count,
          rows: state.bidList.rows.map((bid) => (bid.id === action.payload.id ? action.payload : bid)),
        },
        modalOptions: { ...state.modalOptions, show: false },
      };
    }
    case types.UPDATE_BID_FAIL: {
      return { ...state };
    }
    case types.REMOVE_BID: {
      return { ...state, isFetching: true };
    }
    case types.REMOVE_BID_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: '',
        bidList: {
          count: state.bidList.count - 1,
          rows: state.bidList.rows.filter((bid) => bid.id !== action.payload.id),
        },
      };
    }
    case types.REMOVE_BID_FAIL: {
      return { ...state, isFetching: false, error: action.payload };
    }

    case types.SET_INITIAL_VALUES: {
      if (!action.payload)
        return {
          ...state,
          initialValues: initialStore.bids.initialValues,
          modalOptions: { show: true, isUpdate: false },
        };

      const element = state.bidList.rows.find((el) => el.id === action.payload);
      if (!element) return state;
      const { id, desc, price, status } = element;
      return { ...state, initialValues: { id, desc, price, status }, modalOptions: { show: true, isUpdate: true } };
    }

    case types.TRIGGER_BID_MODAL: {
      return { ...state, modalOptions: { ...state.modalOptions, show: false } };
    }

    default:
      return state;
  }
};
