import * as types from './types';

import { AUTH, AuthActionTypes } from './model';
import { initialStore } from '../initial-store';

export const authReducer = (state = initialStore.auth, action: AuthActionTypes): AUTH => {
  switch (action.type) {
    case types.SIGN_IN: {
      return { ...state, isFetching: true };
    }
    case types.SIGN_IN_SUCCESS: {
      return { ...state, isFetching: false, isAuthorized: true };
    }
    case types.SIGN_IN_FAIL: {
      return { ...state, isFetching: false, error: action.payload };
    }
    case types.SIGN_UP: {
      return { ...state };
    }
    case types.SIGN_UP_SUCCESS: {
      return { ...state };
    }
    case types.SIGN_UP_FAIL: {
      return { ...state };
    }
    case types.LOG_OUT: {
      return { ...state };
    }
    case types.LOG_OUT_SUCCESS: {
      return { ...state, isAuthorized: false };
    }
    case types.LOG_OUT_FAIL: {
      return { ...state };
    }
    // case types.RESTORE_PASSWORD: {
    //   return { ...state };
    // }
    // case types.RESTORE_PASSWORD_SUCCESS: {
    //   return { ...state };
    // }
    // case types.RESTORE_PASSWORD_FAIL: {
    //   return { ...state };
    // }
    case types.SESSION_REFRESH: {
      return { ...state };
    }
    case types.SESSION_REFRESH_SUCCESS: {
      return { ...state };
    }
    case types.SESSION_REFRESH_FAIL: {
      return { ...state };
    }

    default:
      return state;
  }
};
