import { call, takeEvery, put } from 'redux-saga/effects';

import * as types from '../../store/admins/types';
import * as actions from '../../store/admins/actions';

import {
  getAllAdminsSagas,
  createAdminSagas,
  updateAdminSagas,
  removeAdminSagas,
  getCurrentAdminResponse,
  getAllAdminsResponse,
  createAdminResponse,
  updateAdminResponse,
  setDefaultPasswordSagas,
} from '../../store/admins/model';

import { adminsApi } from '../../api/adminsApi';
import { removeEmptyFields } from '../../utils/removeEmptyFields';

function* getAllAdmins({ payload }: getAllAdminsSagas) {
  try {
    const response: getAllAdminsResponse = yield call(adminsApi.getAllAdminsRequest, payload);

    yield put(actions.getAllAdminsSuccess(response));
  } catch (error) {
    yield put(actions.getAllAdminsFail(error.message));
  }
}

function* createAdmin({ payload }: createAdminSagas) {
  const { values, limit, page } = payload;
  try {
    const response: createAdminResponse = yield call(adminsApi.createAdminRequest, removeEmptyFields(values));

    yield put(actions.createAdminSuccess({ admin: response, limit, page }));
  } catch (error) {
    yield put(actions.createAdminFail(error.message));
  }
}

function* updateAdmin({ payload }: updateAdminSagas) {
  try {
    const response: updateAdminResponse = yield call(adminsApi.updateAdminRequest, removeEmptyFields(payload));

    yield put(actions.updateAdminSuccess(response));
  } catch (error) {
    yield put(actions.updateAdminFail(error));
  }
}

function* removeAdmin({ payload }: removeAdminSagas) {
  try {
    yield call(adminsApi.removeAdminRequest, payload);
    yield put(actions.removeAdminSuccess({ id: payload.id }));
  } catch (error) {
    yield put(actions.removeAdminFail(error.message));
  }
}

function* getCurrentAdmin() {
  try {
    const response: getCurrentAdminResponse = yield call(adminsApi.getCurrentAdminRequest);

    yield put(actions.getCurrentAdminSuccess(response));
  } catch (error) {
    yield put(actions.getCurrentAdminFail(error.message));
  }
}

function* setDefaultPassword({ payload }: setDefaultPasswordSagas) {
  try {
    yield call(adminsApi.setDefaultPasswordRequest, payload);
    yield put(actions.setDefaultPasswordSuccess());
  } catch (error) {
    yield put(actions.setDefaultPasswordFail(error.message));
  }
}

function* admin() {
  yield takeEvery(types.GET_ALL_ADMINS, getAllAdmins);
  yield takeEvery(types.CREATE_ADMIN, createAdmin);
  yield takeEvery(types.UPDATE_ADMIN, updateAdmin);
  yield takeEvery(types.REMOVE_ADMIN, removeAdmin);
  yield takeEvery(types.GET_CURRENT_ADMIN, getCurrentAdmin);
  yield takeEvery(types.SET_DEFAULT_PASSWORD, setDefaultPassword);
}

export default admin;
