import { call, takeEvery, put, select } from 'redux-saga/effects';

import * as types from '../../store/member/types';
import * as actions from '../../store/member/actions';
import { membersApi } from '../../api/memberApi';
import {
  getAllMembersSagas,
  createMemberSagas,
  updateMemberSagas,
  removeMemberSagas,
  setDefaultPasswordSagas,
  sendConfirmationEmailSagas,
  getAllMembersResponse,
  createMemberResponse,
  updateMemberResponse,
} from '../../store/member/model';

import { removeEmptyFields } from '../../utils/removeEmptyFields';
import { openNotificationSuccess } from '../../utils/show-notification';

import { getMemberUpdateId } from '../../store/member/selectors';

function* getAllMembers({ payload }: getAllMembersSagas) {
  try {
    const response: getAllMembersResponse = yield call(membersApi.getAllMembersRequest, {
      ...payload,
      type: payload.type && Array.isArray(payload.type) ? undefined : payload.type,
    });

    yield put(actions.getAllMembersSuccess({ response, type: payload.type }));
  } catch (error) {
    yield put(actions.getAllMembersFail(error.message));
  }
}

function* createMember({ payload }: createMemberSagas) {
  const { values, page, limit } = payload;
  try {
    const response: createMemberResponse = yield call(membersApi.createMemberRequest, removeEmptyFields(values));

    yield put(actions.createMemberSuccess({ member: response, page, limit }));
    yield openNotificationSuccess({ message: 'Member created successfully' });
  } catch (error) {
    yield put(actions.createMemberFail(error.message));
  }
}

function* updateMember({ payload }: updateMemberSagas) {
  try {
    const id = yield select(getMemberUpdateId);

    const response: updateMemberResponse = yield call(membersApi.updateMemberRequest, {
      values: removeEmptyFields(payload),
      id,
    });
    yield put(actions.updateMemberSuccess(response));
    yield openNotificationSuccess({ message: 'Member updated successfully' });
  } catch (error) {
    yield put(actions.updateMemberFail(error));
  }
}

function* removeMember({ payload }: removeMemberSagas) {
  try {
    yield call(membersApi.removeMemberRequest, payload);

    yield put(actions.removeMemberSuccess({ id: payload.id }));
    yield openNotificationSuccess({ message: 'Member removed successfully' });
  } catch (error) {
    yield put(actions.removeMemberFail(error.message));
  }
}

function* setDefaultPassword({ payload }: setDefaultPasswordSagas) {
  try {
    yield call(membersApi.setDefaultPasswordRequest, payload);
    yield put(actions.setDefaultPasswordSuccess());
    yield openNotificationSuccess({
      message: 'Password was set to default value, member will receive email notification',
    });
  } catch (error) {
    yield put(actions.setDefaultPasswordFail(error.message));
  }
}

function* sendConfirmationEmail({ payload }: sendConfirmationEmailSagas) {
  try {
    yield call(membersApi.sendConfirmationEmailRequest, payload);
    yield put(actions.sendConfirmationEmailSuccess());
    yield openNotificationSuccess({
      message: 'Client will receive an email with confirmation link',
    });
  } catch (error) {
    yield put(actions.sendConfirmationEmailFail(error.message));
  }
}

function* member() {
  yield takeEvery(types.GET_ALL_MEMBERS, getAllMembers);
  yield takeEvery(types.CREATE_MEMBER, createMember);
  yield takeEvery(types.UPDATE_MEMBER, updateMember);
  yield takeEvery(types.REMOVE_MEMBER, removeMember);
  yield takeEvery(types.SET_DEFAULT_PASSWORD, setDefaultPassword);
  yield takeEvery(types.SEND_CONFIRMATION_EMAIL, sendConfirmationEmail);
}

export default member;
