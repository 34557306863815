import * as types from './types';
import * as model from './model';
import { inferLiteral } from './model';

export const getAllContracts = (payload: model.getAllContractsPayload) =>
  ({
    type: inferLiteral(types.GET_ALL_CONTRACTS),
    payload,
  } as const);

export const getAllContractSuccess = (payload: model.getAllContractSuccessPayload) =>
  ({
    type: inferLiteral(types.GET_ALL_CONTRACTS_SUCCESS),
    payload,
  } as const);

export const getAllContractFail = (payload: model.getAllContractFailPayload) =>
  ({
    type: inferLiteral(types.GET_ALL_CONTRACTS_FAIL),
    payload,
  } as const);

export const getContractById = (payload: model.getContractByIdPayload) =>
  ({
    type: inferLiteral(types.GET_CONTRACT_BY_ID),
    payload,
  } as const);

export const getContractByIdSuccess = (payload: model.getContractByIdSuccessPayload) =>
  ({
    type: inferLiteral(types.GET_CONTRACT_BY_ID_SUCCESS),
    payload,
  } as const);

export const getContractByIdFail = (payload: model.getContractByIdFailPayload) =>
  ({
    type: inferLiteral(types.GET_CONTRACT_BY_ID_FAIL),
    payload,
  } as const);

export const createContract = (payload: model.createContractPayload) =>
  ({
    type: inferLiteral(types.CREATE_CONTRACT),
    payload,
  } as const);

export const createContractSuccess = (payload: model.createContractSuccessPayload) =>
  ({
    type: inferLiteral(types.CREATE_CONTRACT_SUCCESS),
    payload,
  } as const);

export const createContractFail = (payload: model.createContractFailPayload) =>
  ({
    type: inferLiteral(types.CREATE_CONTRACT_FAIL),
    payload,
  } as const);

export const updateContract = (payload: model.updateContractPayload) =>
  ({
    type: inferLiteral(types.UPDATE_CONTRACT),
    payload,
  } as const);

export const updateContractSuccess = (payload: model.updateContractSuccessPayload) =>
  ({
    type: inferLiteral(types.UPDATE_CONTRACT_SUCCESS),
    payload,
  } as const);

export const updateContractFail = (payload: model.updateContractFailPayload) =>
  ({
    type: inferLiteral(types.UPDATE_CONTRACT_FAIL),
    payload,
  } as const);

export const removeContract = (payload: model.removeContractPayload) =>
  ({
    type: inferLiteral(types.REMOVE_CONTRACT),
    payload,
  } as const);

export const removeContractSuccess = (payload: model.removeContractSuccessPayload) =>
  ({
    type: inferLiteral(types.REMOVE_CONTRACT_SUCCESS),
    payload,
  } as const);

export const removeContractFail = (payload: model.removeContractFailPayload) =>
  ({
    type: inferLiteral(types.REMOVE_CONTRACT_FAIL),
    payload,
  } as const);

export const setInitialValues = (payload?: model.setInitialValuesPayload) =>
  ({
    type: inferLiteral(types.SET_INITIAL_VALUES),
    payload,
  } as const);

export const triggerContractModal = () =>
  ({
    type: inferLiteral(types.TRIGGER_CONTRACT_MODAL),
  } as const);

export const setQueryString = (payload: model.setQueryStringPayload) =>
  ({
    type: inferLiteral(types.SET_QUERY_STRING),
    payload,
  } as const);
