import { call, takeEvery, put } from 'redux-saga/effects';

import { bidApi } from '../../api/bidApi';

import * as types from '../../store/bid/types';
import * as actions from '../../store/bid/actions';

import {
  getAllBidsSagas,
  getCurrentBidSagas,
  createBidSagas,
  updateBidSagas,
  removeBidSagas,
} from '../../store/bid/model';

function* getAllBids({ payload }: getAllBidsSagas) {
  try {
    const response = yield call(bidApi.getAllBidsRequest, payload);

    yield put(actions.getAllBidSuccess(response));
  } catch (error) {
    yield put(actions.getAllBidFail(error));
  }
}

function* getCurrentBid({ payload }: getCurrentBidSagas) {
  try {
    const response = yield call(bidApi.getCurrentBidRequest, payload);

    yield put(actions.getCurrentBidSuccess(response));
  } catch (error) {
    yield put(actions.getCurrentBidFail(error));
  }
}

function* createBid({ payload }: createBidSagas) {
  try {
    const response = yield call(bidApi.createBidRequest, payload);

    yield put(actions.createBidSuccess(response));
  } catch (error) {
    yield put(actions.createBidFail(error));
  }
}

function* updateBid({ payload }: updateBidSagas) {
  try {
    const response = yield call(bidApi.updateBidRequest, payload);

    yield put(actions.updateBidSuccess(response));
  } catch (error) {
    yield put(actions.updateBidFail(error));
  }
}

function* removeBid({ payload }: removeBidSagas) {
  try {
    yield call(bidApi.removeBidRequest, payload);

    yield put(actions.removeBidSuccess({ id: payload.id }));
  } catch (error) {
    yield put(actions.removeBidFail(error));
  }
}

function* bid() {
  yield takeEvery(types.GET_ALL_BIDS, getAllBids);
  yield takeEvery(types.GET_BID_BY_ID, getCurrentBid);
  yield takeEvery(types.CREATE_BID, createBid);
  yield takeEvery(types.UPDATE_BID, updateBid);
  yield takeEvery(types.REMOVE_BID, removeBid);
}

export default bid;
