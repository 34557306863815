import React from 'react';
import { Avatar } from 'antd';

type AvatarType = {
  src: string;
  size?: number;
  className?: string;
  shape?: 'circle' | 'square';
};

export const CustomAvatar = ({ src, className = '', shape = 'circle', ...rest }: AvatarType) => {
  return <Avatar src={src} className={className} shape={shape} {...rest} />;
};
