export const LOCALSTORAGE_ACCESS_TOKEN_NAME = 'access_token';
export const LOCALSTORAGE_REFRESH_TOKEN_NAME = 'refresh_token';

export const setToken = (token: string) => localStorage.setItem(LOCALSTORAGE_ACCESS_TOKEN_NAME, token);
export const getToken = (): string | null => localStorage.getItem(LOCALSTORAGE_ACCESS_TOKEN_NAME);
export const removeToken = () => localStorage.removeItem(LOCALSTORAGE_ACCESS_TOKEN_NAME);

export const setRefreshToken = (token: string) => localStorage.setItem(LOCALSTORAGE_REFRESH_TOKEN_NAME, token);
export const getRefreshToken = (): string | null => localStorage.getItem(LOCALSTORAGE_REFRESH_TOKEN_NAME);
export const removeRefreshToken = () => localStorage.removeItem(LOCALSTORAGE_REFRESH_TOKEN_NAME);
