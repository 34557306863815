import * as types from './types';
import * as model from './model';
import { inferLiteral } from './model';

export const signIn = (payload: model.SignInPayload) =>
  ({
    type: inferLiteral(types.SIGN_IN),
    payload,
  } as const);

export const signInSuccess = (payload: model.SignInSuccessPayload) =>
  ({
    type: inferLiteral(types.SIGN_IN_SUCCESS),
    payload,
  } as const);

export const signInFail = (payload: model.SignInFailPayload) =>
  ({
    type: inferLiteral(types.SIGN_IN_FAIL),
    payload,
  } as const);

export const signUp = (payload: model.SignUpPayload) =>
  ({
    type: inferLiteral(types.SIGN_UP),
    payload,
  } as const);

export const signUpSuccess = (payload: model.SignUpSuccessPayload) =>
  ({
    type: inferLiteral(types.SIGN_UP_SUCCESS),
    payload,
  } as const);

export const signUpFail = (payload: model.SignUpFailPayload) =>
  ({
    type: inferLiteral(types.SIGN_UP_FAIL),
    payload,
  } as const);

export const logOut = (payload: model.LogOutPayload) =>
  ({
    type: inferLiteral(types.LOG_OUT),
    payload,
  } as const);

export const logOutSuccess = (payload: model.LogOutSuccessPayload) =>
  ({
    type: inferLiteral(types.LOG_OUT_SUCCESS),
    payload,
  } as const);

export const logOutFail = (payload: model.LogOutFailPayload) =>
  ({
    type: inferLiteral(types.LOG_OUT_FAIL),
    payload,
  } as const);

// export const restorePassword = (payload: model.RestorePasswordPayload) =>
//   ({
//     type: inferLiteral(types.RESTORE_PASSWORD),
//     payload,
//   } as const);

// export const restorePasswordSuccess = (payload: model.RestorePasswordSuccessPayload) =>
//   ({
//     type: inferLiteral(types.RESTORE_PASSWORD_SUCCESS),
//     payload,
//   } as const);

// export const restorePasswordFail = (payload: model.RestorePasswordFailPayload) =>
//   ({
//     type: inferLiteral(types.RESTORE_PASSWORD_FAIL),
//     payload,
//   } as const);

export function sessionRefresh(payload: model.SessionRefreshPayload) {
  return {
    type: inferLiteral(types.SESSION_REFRESH),
    payload: payload,
  };
}

export const sessionRefreshSuccess = (payload: model.SessionRefreshSuccessPayload) =>
  ({
    type: inferLiteral(types.SESSION_REFRESH_SUCCESS),
    payload,
  } as const);

export const sessionRefreshFail = (payload: model.SessionRefreshFailPayload) =>
  ({
    type: inferLiteral(types.SESSION_REFRESH_FAIL),
    payload,
  } as const);
