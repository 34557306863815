import * as types from './types';
import * as model from './model';
import { inferLiteral } from './model';

// getAllCommonInfos
export const getAllCommonInfos = (payload: model.getAllCommonInfosPayload) =>
  ({
    type: inferLiteral(types.GET_ALL_COMMONINFOS),
    payload,
  } as const);
export const getAllCommonInfosSuccess = (payload: model.getAllCommonInfoSuccessPayload) =>
  ({
    type: inferLiteral(types.GET_ALL_COMMONINFOS_SUCCESS),
    payload,
  } as const);
export const getAllCommonInfosFail = (payload: model.getAllCommonInfoFailPayload) =>
  ({
    type: inferLiteral(types.GET_ALL_COMMONINFOS_FAIL),
    payload,
  } as const);
