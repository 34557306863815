import { call, takeEvery, put, select } from 'redux-saga/effects';

import { contractApi } from '../../api/contractApi';

import * as types from '../../store/contract/types';
import * as actions from '../../store/contract/actions';

import {
  getAllContractsSagas,
  createContractSagas,
  updateContractSagas,
  removeContractSagas,
  getAllContractsResponse,
  createContractResponse,
  updateContractResponse,
} from '../../store/contract/model';

import { removeEmptyFields } from '../../utils/removeEmptyFields';
import { openNotificationSuccess } from '../../utils/show-notification';

import { getContractUpdateId } from '../../store/contract/selectors';

function* getAllContracts({ payload }: getAllContractsSagas) {
  try {
    const response: getAllContractsResponse = yield call(contractApi.getAllContractsRequest, payload);
    yield put(actions.getAllContractSuccess(response));
  } catch (error) {
    yield put(actions.getAllContractFail(error));
  }
}

function* createContract({ payload }: createContractSagas) {
  const { values, page, limit } = payload;

  try {
    const response: createContractResponse = yield call(contractApi.createContractRequest, removeEmptyFields(values));

    yield put(actions.createContractSuccess({ contract: response, page, limit }));
    yield openNotificationSuccess({ message: 'Contract was created successfully' });
  } catch (error) {
    yield put(actions.createContractFail(error));
  }
}

function* updateContract({ payload }: updateContractSagas) {
  try {
    const id = yield select(getContractUpdateId);

    const response: updateContractResponse = yield call(contractApi.updateContractRequest, {
      values: removeEmptyFields(payload),
      id,
    });

    yield put(actions.updateContractSuccess(response));
    yield openNotificationSuccess({ message: 'Contract was updated successfully' });
  } catch (error) {
    yield put(actions.updateContractFail(error));
  }
}

function* removeContract({ payload }: removeContractSagas) {
  try {
    yield call(contractApi.removeContractRequest, payload);

    yield put(actions.removeContractSuccess({ id: payload.id }));
    yield openNotificationSuccess({ message: 'Contract was removed successfully' });
  } catch (error) {
    yield put(actions.removeContractFail(error));
  }
}

function* contract() {
  yield takeEvery(types.GET_ALL_CONTRACTS, getAllContracts);
  yield takeEvery(types.CREATE_CONTRACT, createContract);
  yield takeEvery(types.UPDATE_CONTRACT, updateContract);
  yield takeEvery(types.REMOVE_CONTRACT, removeContract);
}

export default contract;
